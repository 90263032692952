<section class="modal_section_div">
    <button mat-dialog-close class="close_md_date">
        <img src="assets/images/close.svg" class="rem_product pointer">
    </button>
    <div class="container make_it_center">
        <div class="row">
            <div class="col-md-12" align='center' *ngIf="!isOtpSuccess">
                <h3>Enter Mobile Number for OTP</h3>

            </div>
            <div class="col-md-12" align='center' *ngIf="isOtpSuccess">
                <h3>Enter OTP</h3>
                <div class="otp_message">An OTP has been sent to your given mobile number</div>
                <span>+91 {{mobile_number.mobile}} <span class="text-primary" (click)="isOtpSuccess=!isOtpSuccess">(Edit)</span> </span>

            </div>
            <div *ngIf="invalid_otp">
                <p class="font">INVALID OTP</p>
            </div>
            <div *ngIf="!isOtpSuccess" class="login_box_div">
                <form [formGroup]='loginForm'>
                    <div class="col-md-12" align="center">
                        <input type="number" class="cart_input" placeholder="Mobile Number*" size="10"
                            formControlName="mobile">
                        <ng-container *ngIf="!submitted ">
                            <span class="font">phone number is not statndard pattern</span>
                        </ng-container>
                    </div>
                    <div class="col-md-12 mt20" align="center">
                        <button type="submit" (click)="submit(loginForm.value,loginForm)" class="next_btn_cart">Get
                            OTP</button>
                    </div>
                </form>
            </div>
            <div *ngIf="isOtpSuccess" class="login_box_div">
                <form>
                    <div class="col-md-12" align="center">
                        <ng-otp-input type="number" #otp (onInputChange)="onOtpChange($event)" [config]="{length:4}">
                        </ng-otp-input>
                        <ng-container *ngIf="otp_invalid">
                            <span class="font">Invalid OTP</span>
                        </ng-container>
                    </div>
                    <div class="col-md-12 mt20" align="center">
                        <button type="submit" (click)="submitOtp()" class="next_btn_cart">Verify</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<div class="loader_order" *ngIf="is_place">
    <div class="load_box">
        <ngx-moon-loader class="item"></ngx-moon-loader>
    </div>
</div>