<section id="innerbanner">
    <div class="container">
        <div class="row">
            <div class="breadcrumb">
                <h1>My Profile</h1>
                <span><a [routerLink]="['/']" title="Home">Home</a> / <a title="Profile">Profile</a></span>
            </div>
        </div>
    </div>
</section>
<div class="clearfix"></div>
<section class="profile">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="heading_box">
                    <div class="row">
                        <div class="col-md-6 col-xs-6 tl">
                            <div class="h_b_text">
                                Personal Details
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-6 tr">
                            <div class="hb_edit">
                                <img src="assets/images/edit.png" (click)="editProfile()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt20">
                <table class="table res_table">
                    <tr>
                        <td class="medum">Name</td>
                        <td>: {{profile?.customer_name}}</td>
                    </tr>
                    <tr>
                        <td class="medum">Contact Number</td>
                        <td>: {{profile?.customer_mobile}}</td>
                    </tr>
                    <tr *ngIf="profile?.customer_email">
                        <td class="medum">Email</td>
                        <td>: {{profile?.customer_email}}</td>
                    </tr>
                    <tr *ngIf="profile?.customer_group ==null">
                        <td class="medum">Customer Type</td>
                        <td>: {{profile?.customer_type}}</td>
                    </tr>
                    <tr *ngIf="profile?.customer_group !=null">
                        <td class="medum">Customer Type</td>
                        <td>: {{profile?.customer_group?.customer_group_name}}</td>
                    </tr>
                    <tr *ngIf="profile?.customer_type !='personal'">
                        <td class="medum">Gst Number</td>
                        <td>: {{profile?.gst_number}}</td>
                    </tr>
                </table>

            </div>
        </div>

        <div class="row mt20">
            <div class="col-md-12">
                <div class="heading_box">
                    <div class="row">
                        <div class="col-md-6 tl">
                            <div class="h_b_text">
                                Address Details
                            </div>
                        </div>
                        <div class="col-md-6 tr">
                            <div class="hb_edit">
                                <!-- <img src="assets/images/edit.png"> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt20">
                <table class="table t2 res_table">
                    <tr *ngFor="let ad of address; let i=index">
                        <td class="medum">Address {{i+1}}</td>
                        <td class="ad_size">{{ad.address_street_address}},
                            {{ad.city.city_name}},
                            {{ad.neighbourhood.neighbourhood_name}},<br>
                            {{ad.address_pincode}},<br>
                            {{ad.address_mobile}}
                        </td>
                        <td class="tr">
                            <img  (click)="deleteAddress(ad._id)" src="assets/images/close.png" class="rem_product pointer">
                        </td>
                    </tr>
                    <tr class="tl">
                        <button class="view_or_detail" (click)="editAddress()">Add</button>
                    </tr>
                </table>
                <div class="full-text-bg2" *ngIf="address==undefined|| address==null">not any address found</div>
            </div>
            <div class="col-md-12 tr mt20">
                <button class="logout_pr" (click)="logout()">Log out</button>
            </div>
        </div>
    </div>
</section>