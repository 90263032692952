<!-- header -->
<!-- @extends('front.master')

@section('title', 'About')

@section('content') -->
<!-- /header -->


<section id="innerbanner">
	<div class="container">
		<div class="row">
			<div class="breadcrumb">
				<h1 class="wow fadeInUp">About Us</h1>
				<span class="wow fadeInUp"><a [routerLink]="['/']">Home</a> / <a>About</a></span>
			</div>
		</div>
	</div>
</section>

<div class="clearfix"></div>

<!-- About -->
<section>
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<h1 class="wow fadeInUp">Kriztle</h1>
				<hr class="wow fadeInUp hidden-xs">
				<p class="wow fadeInUp">KRIZTLE has a strong and reliable record of delivering supreme quality drinking water. We provide supreme importance to our customers’ requirements. The innovations that we have implemented in every aspect, right from sourcing and purifying till delivery is a great proof of responsibility that we display always.</p>
				<p class="wow fadeInUp">We stay close to nature always and concurrently we adopt most modern technological factors to satisfy the changing needs of our customers in healthiest way.</p>
			</div>
			<div class="col-md-6 about-img">
				<img src="assets/images/about-img.png" class="img-responsive wow fadeInUp"/>
			</div>
		</div>
	</div>
</section>
<!--// About -->

<div class="clearfix"></div>

<!-- qoute -->
<!--<section class="qoutesection">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<p> <span><i class="fa fa-quote-left"></i></span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.psum is simply dummy text of the printing and typesetting industry <span><i class="fa fa-quote-right"></i></span></p>
			</div>
		</div>
	</div>
</section>-->
<!--// qoute -->



<div id="kriztleprocess"></div>

<div class="clearfix"></div>


<!-- process -->
<section class="process class1">
	<div class="container">
		<div class="row">
			<div class="col-md-12 title-content">
				<h1 class="wow fadeInUp">Purification Process</h1>
				<p class="wow fadeInUp sp">Kriztle always emphasis to maintain consistent quality in each strides that is taken. To ensure quality and natural purity, Kriztle implements numerous quality assurance processes before bottling. The procedures are intended to guarantee the highest safety standards for drinking water and ensure a taste that is in accordance with consumer preference.</p>
			</div>
			<img src="assets/images/process.jpg" class="img-responsive wow fadeInUp"/>
			
			<div class="col-md-12 pro-steps">
				<div class="col-md-6">
					<h3 class="wow fadeInUp"><span>01</span>Natural  Water Source</h3>
					<p class="wow fadeInUp">Sourced from the purest springs of God’s own country we provide utmost care to maintain consistent quality of bottled water.</p>
				</div>
				<div class="col-md-6">
					<h3 class="wow fadeInUp"><span>02</span> Pre filtration Process</h3>
					<p class="wow fadeInUp">In the bottling process, initially rapid and efficient removal of suspended particles are done through rapid filtration method followed by Activated carbon method process to make water further pure and disinfectant.</p>
				</div>
				<div class="col-md-6">
					<h3 class="wow fadeInUp"><span>03</span> Reverse Osmosis Process</h3>
					<p class="wow fadeInUp">Consequently Reverse osmosis is carried out to get rid of adverse ions and other molecules.</p>
				</div>
				<div class="col-md-6">
					<h3 class="wow fadeInUp"><span>04</span> UV Ozone Treatment</h3>
					<p class="wow fadeInUp">The Next phase of quality Procedure includes Micron filtration method which consists of four different attentive stages to make water absolutely safe. The clean crispy water is further Purified using ultraviolet (UV) method and then Ozonated before bottling.</p>
				</div>
				<div class="col-md-6">
					<h3 class="wow fadeInUp"><span>05</span> Final Product Storage</h3>
					<p class="wow fadeInUp">The purest water is bottled in highly hygienic Kriztle bottles which have consistent thickness and specified weight to preserve the quality for a long time. The scientific packing of these bottles is tech-unique for Kriztle and is highly refined.</p>
				</div>
				
			</div>
			
			
			
		</div>
	</div>
</section>
<!--// process -->



<!-- @endsection -->