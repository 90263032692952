import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppRoutingModule, CheckOutActivate } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './components/common/layout/layout.module';
import { HomeComponent } from './views/home/home.component';
import { AboutComponent } from './views/about/about.component';
import { ProductsComponent } from './views/products/products.component';
import { ContactComponent } from './views/contact/contact.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CartComponent } from './views/cart/cart.component';
import { ProfileComponent } from './views/profile/profile.component';
import { MyOrdersComponent } from './views/my-orders/my-orders.component';
import { LoginComponent } from './views/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { AddAddressComponent } from './views/add-address/add-address.component';
import { OrderCompletedComponent } from './views/order-completed/order-completed.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { OrderDetailsComponent } from './views/order-details/order-details.component'
import { MatDialogModule } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';
import { Dialog1Component } from './dialog1/dialog1.component';
import { Dialog2Component } from './dialog2/dialog2.component';
import { AgmCoreModule } from '@agm/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DateTimePickerComponent } from './views/date-time-picker/date-time-picker.component';
import { CancelOrderComponent } from './views/cancel-order/cancel-order.component';
import {MatRadioModule} from '@angular/material/radio';
import { AddProfileComponent } from './views/add-profile/add-profile.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { SchemeComponent } from './views/scheme/scheme.component';
import { DecimalPipe } from '@angular/common';
import {NgxSpinnersModule} from 'ngx-spinners';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TermsComponent } from './views/terms/terms.component'



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ProductsComponent,
    ContactComponent,
    CartComponent,
    ProfileComponent,
    MyOrdersComponent,
    LoginComponent,
    CheckoutComponent,
    AddAddressComponent,
    OrderCompletedComponent,
    OrderDetailsComponent,
    DialogComponent,
    Dialog1Component,
    Dialog2Component,
    DateTimePickerComponent,
    CancelOrderComponent,
    AddProfileComponent,
    SchemeComponent,
    TermsComponent,
    // BasicLayoutComponent
  ],
  entryComponents: [DialogComponent,Dialog1Component,Dialog2Component,DateTimePickerComponent,CancelOrderComponent,AddProfileComponent,
  AddAddressComponent,LoginComponent,OrderCompletedComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppRoutingModule,
    HttpModule,
    LayoutModule,
    CarouselModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FormsModule,
    MatRadioModule,
    NgxSpinnersModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),

    NgOtpInputModule,
    SelectDropDownModule,
    MatDialogModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAwDgltX1UUiV_Hc5YiMx8mdVrLbtBUM7U'
    })
  ],
 
  providers: [CheckOutActivate,DecimalPipe,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
