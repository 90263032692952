<section class="modal_section_div">
  <button mat-dialog-close class="close_md_date">
    <img src="assets/images/close.svg" class="rem_product pointer">
  </button>
  <div class="container make_it_center">
    <div class="row">
      <div class="col-md-12">
        <table class="modal_sm_table" *ngIf="customer_group_name != 'dealer' && customer_group_name != 'distributors'">
          <tr>
            <th>Box Qty.</th>
            <th>Rate per Box</th>
          </tr>
          <tr mat-dialog-close *ngFor='let data of bulkList' [mat-dialog-close]=data>
            <td *ngIf="data.max_qty!='nan'">{{ data.min_qty }}-{{ data.max_qty}}</td>
            <td *ngIf="data.max_qty=='nan'">{{ data.min_qty }} and above</td>
            <td>{{ data.product_price |number:'1.2-2' | currency:'INR'}}</td>
          </tr>
          <tr mat-dialog-close *ngIf="bulkList.length==0">
            <td colspan="2">no data found</td>
          </tr>
        </table>


        <table style="width:100%; border-radius:30px !important;" *ngIf="customer_group_name == 'dealer' || customer_group_name == 'distributors'">
          <tr>
            <th class="padd-left">Purchase<br> Amount (per Lak)</th>
            <th class="padd-left special-bold">Your gift</th>
            <th class="padd-left">Graph</th>
          </tr>
          <tr mat-dialog-close   *ngFor='let plan of scehemePlan'>
            <td class="padd-left">{{ plan.min_point/100000 }}</td>
            <td class="padd-left">{{ plan.benefits }}</td>
            <td class="padd-left bg-colorthis1"></td>
          </tr>

        </table>

        
      </div>
    </div>
  </div>
</section>