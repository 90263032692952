import { ApiService } from './api.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OtherService {

  total_price:any;
  track_id:any;
  edit_address:any;
  cart_items:any;
  selected_datetime:any;
  cart_item:any;
  total_qty:any;
  total:any;
  user_details:any= JSON.parse(localStorage.getItem("user_details"));

  constructor(public http:HttpClient, public api:ApiService) { }

  getAddress(){
    let token = JSON.parse(localStorage.getItem('api_token'));
      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set("Authorization", "Bearer " + token);
    let endpoint="address"
    return this.http.get(this.api.url+endpoint,{ headers: headers });
  }

  getCities(){
    let endpoint = "location/cities"
    return this.http.get(this.api.url+endpoint);
  }

  getNeighborhood(id){
    let endpoint = "location/neighbourhood/"
    return this.http.get(this.api.url+endpoint+id);

  }

  saveAddress(details){
    let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint="address";

 
    return this.http.post(this.api.url+endpoint,details,{ headers: headers });


}

getTotalQty(){
  this.cart_item=JSON.parse(localStorage.getItem('cart'));
  console.log(this.cart_item)
  this.total_qty=0;
  console.log(this.cart_item)
    if(this.cart_item !=null){
      console.log("working")
    this.cart_item.forEach(element => {
      console.log(element.qty)
    this.total_qty+=element.qty;
    console.log(this.total_qty);
  });
}
  //     this.total_qty=this.prod_qty.reduce(function(a, b){
  //     return a + b;
  //   }, 0);


}

getSchemes(){
  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint = 'scheme';
  return this.http.get(this.api.url+endpoint, { headers: headers });
}

placeOrder(data){
  let endpoint="orders/place";

  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);

  return this.http.post(this.api.url+endpoint,data,{ headers: headers });
}

getOrders(page,perpage,status){
  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint='orders?';
  if (page > 0){
    endpoint += 'page=' + page + '&';
  }
  if (perpage > 0){
    endpoint += 'per_page=' + perpage + '&';
  }
  if (status !=null|| status!=""||status!=undefined){
    endpoint += 'order_status=' + status;
  }
  return this.http.get(this.api.url+endpoint,{ headers: headers });
}

trackOrder(id){
  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint='orders/view/';
  return this.http.get(this.api.url+endpoint+id,{ headers: headers });
}

getProfile(){
  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint='me';
  return this.http.get(this.api.url+endpoint, { headers: headers });
}

deleteAddress(id){
  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint='address/delete/';
  return this.http.delete(this.api.url+endpoint+id, { headers: headers });
  
}

commonSettings() {
  let endpoint = 'app_settings';
  // return this.http.get('https://api.foodsign.apps.eximuz.com/api/v1/customers/'+endpoint);
  return this.http.get(this.api.url+endpoint);
}

verifyPayment(data) {
  let endpoint = 'orders/verify-payment';
  return this
    .api
    .post(endpoint, data)
    .pipe(map(resp => resp.json()));
}


cancelOrder(id,reason){
  let data:any={
    'cancel_reason':reason
  }
  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint = 'orders/cancel/' + id;
  return this.http.put(this.api.url+endpoint,data,{ headers: headers });
}

getDeliveryCharge(location_id=0) {
  let endpoint = 'delivery-charge?vendor_available_location_id=' + location_id;
  return this
    .api
    .get(endpoint)
    .pipe(map(resp => resp.json()));
}

getTotalAmount(){
  console.log("77777777777777")
  this.total=0;
  this.user_details=JSON.parse(localStorage.getItem("user_details"));
  this.cart_items=JSON.parse(localStorage.getItem('cart'));
  if(this.cart_items !=null){
  this.cart_items.forEach(element => {
    console.log(element)
    if(element.bulk_prices !=null){
    element.bulk_prices.forEach(element1 => {
      console.log(this.user_details)
      // if(this.user_details && this.user_details.customer_group_id !=null){
      // if(this.user_details.customer_group_id==element1.customer_group_id){
      if(element.qty >= element1.min_quantity ){
        element.product_price=element1.product_price
        console.log(element.product_price)
      }
      // if(element.qty < element1.min_quantity){
     
      //   element.product_price=element.product_og_price
      // }
  //   }
  // }
    });
  }
    // this.cart_items.forEach(element => {
      this.total=this.total+(element.qty*element.product_price)
      this.total_price=this.total;
      console.log(this.total_price)
    //  });
  });
}
}

}
