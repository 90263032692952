import { ApiService } from './api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(public http:HttpClient,public api:ApiService) { }

  getAllProducts(page,perpage){
    let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint = 'catalog/products?';
  if (page > 0)
      endpoint += '&page=' + page
  if (perpage > 0)
      endpoint += '&&per_page=' + perpage;
  return this.http.get(this.api.url+endpoint,{ headers: headers });
}

getFeaturedProduct(page,perpage){
  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint='catalog/products?category_id=2';
  if (page > 0)
      endpoint += '&page=' + page
  if (perpage > 0)
      endpoint += '&&per_page=' + perpage;
  return this.http.get(this.api.url+endpoint,{ headers: headers });
  }


getProducts(page,perpage){
  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint = 'catalog/products?category_id=1';
  if (page > 0)
      endpoint += '&page=' + page
  if (perpage > 0)
      endpoint += '&&per_page=' + perpage;
  return this.http.get(this.api.url+endpoint,{ headers: headers });
}


getCatogories(){
  let endpoint='catalog/categories'
  return this
  .api
  .get(endpoint)
  .pipe(map(resp=>resp.json()))
}

getCatogeriesProducts(page,perpage,id){
  let token = JSON.parse(localStorage.getItem('api_token'));
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Bearer " + token);
  let endpoint = 'catalog/products?category_id='+id;
  if (page > 0)
      endpoint += '&page=' + page
  if (perpage > 0)
      endpoint += '&&per_page=' + perpage;
  return this.http.get(this.api.url+endpoint,{ headers: headers });
}
}
