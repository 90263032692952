<section id="innerbanner">
	<div class="container">
		<div class="row">
			<div class="breadcrumb">
				<h1 class="wow fadeInUp">Your Cart</h1>
				<span class="wow fadeInUp"><a [routerLink]="['/']" title="Home">Home</a> / <a title="Products">Cart</a></span>
			</div>
		</div>
	</div>
</section>

<div class="clearfix"></div>


<section class="product_caro pb0">
	<div class="container">
		<div class="row">
			<div class="clearfix"></div>
			<div class="col-md-12" *ngIf="cart_items!==null||cart_items!==undefined">
				<div class="row">
					<div class="col-md-8">
						<div class="product_slider_home">
							<div class="row_par">
								<div class="row">

									<div class="col-md-4 col-xs-6 od_ev_no_padding" *ngFor="let prod of cart_items; let i=index">
										<div class="product_div">
											<div class="product_inner">
												<img (click)="removeItem(prod)" src="assets/images/cancel.svg" class="rem_product pointer">
												<div class="pr_image">
													<img [src]="prod.image_url" class="img-responsive" alt="Sprinkle" />
												</div>
												<div class="pr_info_div">
													<div class="pr_info">
														<div class="row">
															<div class="col-md-7 col-xs-12">
																<p class="naming-detail fc_font m0">Kriztle</p>
																<div class="product_name">
																	{{ prod.product_name }}
																</div>
															</div>
															<div class="col-md-5 col-xs-12 pr_md_price">
																<div class="product_name">
																	₹{{prod.change_price |number:'1.2-2'}}
																</div>
															</div>
														</div>
														<div class="row">
															<div class="col-md-12 col-xs-12 tl pr0">
																<div class="product_dec">
																	{{ prod.product_description }}
																</div>
															</div>
															<div class="col-md-12 col-xs-12 hide_web">
																<div class="product_name">
																	₹{{prod.change_price |number:'1.2-2'}}
																</div>
															</div>
														</div>
													</div>
													<div class="product_info">
														<div class="row">
															<div class="col-md-12 col-xs-12 tl pr0">
																<div class="product_info_small">Delivery Free</div>
																<div class="product_info_small2" (click)="priceDetails(prod._id)">
																	Buy in multiple quantity <br> and save more...
																</div>
															</div>
														</div>
													</div>
													<div class="pr_action_list">
														<div class="row">
															<div class="btn-group btn-align" role="group" align="center">
																<button type="button" class="plus-btn" (click)="increaseProduct(prod,'dec')"><span
																		class="ti-minus">-</span></button>
																<input type="number" readonly class="pr_in_count" [value]="prod.qty">
																<button type="button" class="minus-btn" (click)="increaseProduct(prod,'inc')"><span
																		class="ti-plus">+</span></button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4" *ngIf="cart_items && cart_items.length > 0">
						<div class="pr_cart_side">
							<div class="cart_item_h">
								<span class="product_name">Cart items</span>
							</div>
							<div>
								<div class="cart_pr_div">
									<table class="cart_table">
										<tr *ngFor="let prod of cart_items; let i=index">
											<td>{{ prod.product_name }}</td>
											<td>₹{{prod.change_price | number:'1.2-2'}}</td>
											<td>x {{ prod.qty }}(box)</td>
											<td>₹{{prod.change_price * prod.qty | number:'1.2-2'}}</td>
										</tr>
										<tr>
											<td colspan="4" style="border-bottom:1px solid gray;"></td>
										</tr>
										<tr>
											<td class="cart_tt">
												Total
											</td>
											<!-- <td class="cart_tt">₹{{getTotalAmount()}}/-</td> -->
											<td class="cart_tt"> {{getTotalQty()}}</td>
											<td></td>
											<td class="cart_tt">
												₹{{getTotalAmount()}}
											</td>
										</tr>
									</table>
									<div class="table_cart_button mt20">
										<button (click)="continue()" class="next_btn_cart cart_btn">Next</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</section>


<section class="cart_price_padding" [ngClass]="{'pt0': cart_items && cart_items.length <= 0 || cart_items == null}">
	<div *ngIf="cart_items && cart_items.length <= 0 || cart_items == null" class="tc">
		<img src="assets/images/noItem.png" class="cart_empty">
		<div>
			<h2>No Items Found!</h2>
		</div>
		<div class="mt20">
			<button class="next_btn_cart mt20" [routerLink]="['/home']">Continue Shopping</button>
		</div>
	</div>
</section>