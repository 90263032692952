<section id="innerbanner">
  <div class="container">
    <div class="row">
      <div class="breadcrumb">
        <h1>My Scheme</h1>
        <span><a [routerLink]="['/']" title="Home">Home</a> / <a title="My Scheme">My Scheme</a></span>
      </div>
    </div>
  </div>
</section>
<div class="clearfix"></div>
<section class="profile">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12 tc">
          <h2 class="prime">Total Sales: ₹{{points |number:'1.2-2'}}</h2>
        </div>
      </div>
      <!-- <div class="col-md-12">
        <div class="po_border">
          <table class="tbl_width">
            <tr *ngFor="let scheme of plan">
              <td class="tr_w">
                <div class="pr_parent">
                  <span class="ben_name">{{scheme.benefits}}</span>
                  <div class="point_bar" [ngStyle]="{'width.%':getWidth(scheme.min_point)}" style="height:24px;"></div>
                </div>
              </td>
              <td>
                <span>₹{{scheme.min_point |number:'1.2-2' }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div> -->
      <div class="col-md-12 mb20">
        <div class="addres_bar_h">
          <span class="block tc">
            <h6>Delivery Conditions</h6>
            <span>Ends on {{ending_date |date:'dd, MMM ,YYYY'}}</span>
          </span>
        </div>
      </div>
      <div class="col-md-12">
        <section id="conference-timeline">
          <ng-container *ngFor="let scheme of plan; let i=index">
            <!-- <progress  *ngIf="i <1"   value="{{getWidth()}}" max="100"></progress> -->
            <div class="conference-center-line" *ngIf="i <1" style="z-index: 0;" [ngStyle]="{'height.%':getWidth()}" ></div>
          <div class="conference-center-line" style="background-color: gray;"></div>
          </ng-container>
          <div class="conference-timeline-content">
            <!-- active_index -->
            <div class="timeline-article" *ngFor="let scheme of plan; let i = index" [ngClass]="{'active_index ':scheme.min_point<=points}">
              <div class="content-left-container " *ngIf="i%2 == 0">
                <div class="content-left">
                  <p>
                    <span class="poin_info">
                      {{scheme.benefits}}
                    </span>
                    <span >
                      ₹{{scheme.min_point}}
                    </span>
                  </p>
                  <!-- <p>When I orbited the Earth in a spaceship, I saw for the first time how beautiful our planet is. Mankind,
                    let us preserve and increase this beauty, and not destroy it! </p> -->
                </div>

              </div>
              <div class="content-right-container" *ngIf="i%2 != 0">
                <div class="content-right">
                  <p>
                    <span class="poin_info">
                      {{scheme.benefits}}
                    </span> 
                    <span >
                      ₹{{scheme.min_point}}
                    </span>
                  </p>
                  <!-- <p>When I orbited the Earth in a spaceship, I saw for the first time how beautiful our planet is. Mankind,
                    let us preserve and increase this beauty, and not destroy it! </p> -->
                </div>
              </div>
              <div class="meta-date" style="background-color: gray;" [ngClass]="{'active':scheme.min_point<=points}">
                <span class="date">{{ i+1 }}</span>
                <!-- <span class="month">Points</span> -->
              </div>
            </div>

          </div>

        </section>
      </div>

    </div>
  </div>
</section>