<section class="modal_section_div">
    <button mat-dialog-close class="close_md_date">
        <img src="assets/images/close.svg" class="rem_product pointer">
    </button>
    <div class="container make_it_center">
        <div class="row">
            <div class="col-md-12" align='center'>
                <h2>Add Profile</h2>
                <hr>
            </div>
            <div class="col-md-12">
                <div class="tc paymet_info">
                    <button (click)="customerType('p')" [ngClass]="{'personal':customerModel=='personal'}" class="active_b">Personal</button>
                    <button (click)="customerType('c')" [ngClass]="{'corporate':customerModel=='corporate'}" class="active_b">Corporate</button>
                </div>
            </div>
            <div class="col-md-12 margin0auto mt20">
                <div class="ad_p_form">
                    <form [formGroup]='profileForm'>
                        <div class="row">
                            <div class="col-md-12" align="center">
                                <input type="text" class="profile-inp2" placeholder="Name*" formControlName="name">
                                <div *ngIf="submitted && acf.name.errors">
                                    <div class="form-error-msg" *ngIf="acf.name.errors.required">*Name is
                                        required</div>
                                </div>
                            </div>
                            <div class="col-md-12" align="center">
                                <input type="text" class="profile-inp2" placeholder="Contact*" formControlName="phone">
                                <div *ngIf="submitted && acf.phone.errors">
                                    <div class="form-error-msg" *ngIf="acf.phone.errors.required">*Mobile Number is
                                        required</div>
                                    <div class="form-error-msg"
                                        *ngIf="acf.phone.errors.pattern || acf.phone.errors.minlength">
                                        Enter Valid mobile number</div>
                                </div>
                            </div>
                            <div class="col-md-12" align="center">
                                <input type="text" class="profile-inp2" placeholder="E-mail" formControlName="email">
                            </div>
                            <div *ngIf="customerModel=='corporate'" class="col-md-12" align="center">
                                <input type="text" class="profile-inp2" placeholder="GST*" formControlName="gst">
                            </div>
                            <div class="col-md-12" align="center" *ngIf="!isEdit">
                                <button type="button" class="next_btn_cart" (click)="addProfile()">Add Profile</button>
                            </div>
                            <div class="col-md-12" align="center" *ngIf="isEdit">
                                <button type="button" class="next_btn_cart" (click)="addProfile()">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>