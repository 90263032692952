import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/providers/cart.service';
import { UserService } from 'src/app/providers/user.service';
import { HostListener } from "@angular/core";
import { LoginComponent } from 'src/app/views/login/login.component';
import { MatDialog } from '@angular/material';
import { CommonProvider } from 'src/app/providers/common';
import { OtherService } from 'src/app/providers/other.service';
import { Router } from '@angular/router';
import { EventPublishService } from '../../../providers/event-publish';

@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.css']
})
export class TopnavbarComponent implements OnInit {
  @HostListener('window:resize', ['$event'])

  

  isAccount: boolean = false;
  User: boolean;
  check: any;
  status: boolean = true;
  screenWidth;
  profile;
  user_details: any;
  customer_group: any;
  mobile_nav: boolean = false;

  constructor(public user: UserService, public cart: CartService, public dailog: MatDialog, public common: CommonProvider, public otherservice: OtherService, public router: Router, public events: EventPublishService) {
    this.onResize();
    events.getObservable().subscribe((data) => {
      if (data == 'login') {
        this.getCheck();
      }

    });
  }

  ngOnInit() {
    this.getCheck();
    this.cart.getTotalQuantity();
    console.log(this.cart.total_qty);
    if (window.innerWidth <= 768) {
      this.mobile_nav = true;
      
    }

  }


  getCheck() {
    this.User = JSON.parse(localStorage.getItem('user'))
    this.user_details = JSON.parse(localStorage.getItem('user_details'))
    if (this.user_details.data.customer_group != null) {
      this.customer_group = this.user_details.data.customer_group.customer_group_name;
    }
    console.log(this.customer_group)
    console.log(this.User)
  }
  onResize(event?) {
    console.log(window.innerWidth)
    if (window.innerWidth <= 768) {
      this.status = false;
      this.isAccount = true
    }
  }

  accountView() {
    if (this.isAccount == false) {
      this.isAccount = true;
    } else if (this.isAccount == true) {
      this.isAccount = false;
    }
  }


  viewCart() {
  }
  openNav() {
    this.status = !this.status;
  }
  closeNav() {
    if (window.innerWidth <= 768) {
      this.status = false;

    }
  }

  gotoLogin() {
    const dialogRef = this.dailog.open(LoginComponent, {
      panelClass: "modal_sm"
    })
    dialogRef.afterClosed();
  }


  logout() {
    this.isAccount = false;
    this.user.isuser = false;
    this.common.hasLoggedIn = false;
    localStorage.setItem("isUser", JSON.stringify(false));
    localStorage.setItem("user", JSON.stringify(false));
    localStorage.removeItem("api_token");
    localStorage.removeItem("user_details");
    localStorage.removeItem("cart");
    this.user.hasLoggedIn = false;
    this.otherservice.total_price = 0;
    this.cart.total_qty = 0;
    this.common.showSuccess("successfully logged out")
    this.profile = '';
    this.router.navigate(['home']);
    this.events.publishSomeData("logout")
  }



}
