<section id="innerbanner">
    <div class="container">
        <div class="row">
            <div class="breadcrumb">
                <h1 class="wow fadeInUp">Order Detail</h1>
                <span class="wow fadeInUp"><a [routerLink]="['/']" title="Home">Home</a> / <a title="Order Detail">Order
                        Detail</a></span>
            </div>
        </div>
    </div>
</section>
<div class="clearfix"></div>

<section class="order_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="myorderlist product_inner mb20">
                    <div class="row order-area">
                        <div class="col-md-4 orderinfo">
                            <h5 class="product_name">ORDER DETAILS</h5>
                            <div class="col-md-12 zero ">
                                <div class="col-md-4 zero m_top5"><strong>Order ID</strong></div>
                                <div class="col-md-8 zero">#{{orders._id}} &nbsp;<span
                                        *ngIf="orders.products.length == 1">({{orders.products.length}} item)</span>
                                    <span *ngIf="orders.products.length != 1">({{orders.products.length}} items)</span>
                                </div>
                            </div>
                            <div class="col-md-12 zero orderid">
                                <div class="col-md-4 zero m_top5"><strong>Order Date</strong> </div>
                                <div class="col-md-8 zero">{{orders.created_at |date:'dd MMM, yyyy , h:mm a'}}</div>
                            </div>
                            <div class="col-md-12 zero orderid">
                                <div class="col-md-4 zero m_top5"><strong>Total Amount</strong> </div>
                                <div class="col-md-8 zero">₹ {{orders.order_amount}} </div>
                            </div>
                            <div class="col-md-12 zero orderid">
                                <div class="col-md-4 zero m_top5"><strong>Payment Method</strong></div>
                                <div class="col-md-8 zero"><span class="ng-star-inserted"
                                        *ngIf="orders.payment_mode=='cod'">Cash on delivery</span> <span
                                        class="ng-star-inserted" *ngIf="orders.payment_mode=='online'">Online
                                        Payment</span> <span class="ng-star-inserted"
                                        *ngIf="orders.payment_mode=='bank_transfer'">Bank Transfer</span></div>
                            </div>
                        </div>
                        <div class="col-md-5 orderinfo m_top5">
                            <h5 class="product_name">DELIVERY DETAILS</h5>
                            <!-- <div  class="col-md-12 zero orderid">
                                <div  class="col-md-12 zero">fbfd</div>
                            </div> -->
                            <div class="col-md-12 zero name_no_padding">
                                <div class="col-md-12 zero">
                                    <div class="col-md-4 zero "><strong>Name</strong></div>
                                    <div class="col-md-8 zero"> {{address?.address_name}}</div>

                                </div>
                                <div class="col-md-12 zero orderid">
                                    <div class="col-md-4 zero " *ngIf="address.address_mobile">
                                        <strong>Phone</strong>
                                    </div>
                                    <div class="col-md-8 zero">{{address?.address_mobile}}</div>
                                </div>
                                <div class="col-md-12 zero orderid">
                                    <div class="col-md-4 zero "><strong>City</strong></div>
                                    <div class="col-md-8 zero">{{address?.city?.city_name}}</div>
                                </div>
                                <div class="col-md-12 zero orderid">
                                    <div class="col-md-4 zero "><strong>Location</strong></div>
                                    <div class="col-md-8 zero">{{address?.neighbourhood.neighbourhood_name}}</div>
                                </div>
                                <div class="col-md-12 zero orderid">
                                    <div class="col-md-4 zero "><strong>Address</strong></div>
                                    <div class="col-md-8 zero">{{address?.address_street_address}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 zero orderid">
                                <div class="col-md-4 zero"> <strong>Delivery Date & Time</strong> </div>
                                <div class="col-md-8 zero">{{orders.scheduled_date_time|date:'medium'}}</div>
                            </div>
                        </div>
                        <div class="col-md-3 orderinfo">
                            <!-- <h5 >Manage Order</h5> -->
                            <div class="col-md-12 zero orderid">
                                <div class="col-md-12 zero">
                                    <!-- <a  href="#" class="cncel" style="float: left;"><i  class="fa fa-file-alt"></i> Request Invoice</a> -->
                                </div>
                                <div class="col-md-12 zero">
                                    <button class="button_cancel"
                                        *ngIf="orders.order_status!='delivered' && orders.order_status!='cancelled'"
                                        (click)="cancelOrder(orders._id)">Cancel Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mt20">
                            <ol class="progtrckr" data-progtrckr-steps="5">
                                <li *ngFor="let status of orderStatus" class="progtrckr-done"
                                    [ngStyle]="{'opacity': status.date !='' ? '1' : '0.2' }"
                                    [ngClass]="status.status === 'cancelled' ? 'cencelled' : 'progtrckr-done'">

                                    <span class="ss_ss">{{status.name}}</span>
                                    <span class="ss_date">{{status.date|date:'medium'}}</span>
                                </li>
                                <!-- <li class="progtrckr-done">Order Processing</li>
                                <li class="progtrckr-done">Packed</li>
                                <li class="progtrckr-todo">Shipped</li>
                                <li class="progtrckr-todo">Delivered</li> -->
                            </ol>
                        </div>
                    </div>
                </div>

                <div class="order_loop product_inner" *ngFor="let prod of orders.products">
                    <div class="row">
                        <div class="col-md-2 col-xs-1 smpr0 smpl0 tl">
                            <div class="product_image">
                                <img [src]="prod.product_details.image_url" class="img-responsive mb0" alt="" />
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-3 mdleft tc">
                            <div class="pr_info">
                                <div class="product_name">
                                    {{ prod.product_details.product_name }}
                                </div>
                                <div class="product_dec">
                                    {{ prod.product_details.product_description }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-5 tr">
                            <div class="product_name">
                                ₹{{prod.product_price/prod.product_qty | number:'.2-2'}} x {{prod.product_qty}} (Box)
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-3 tr smpl0">
                            <div class="product_name">
                                ₹{{prod.product_price | number:'.2-2'}}/-
                            </div>
                            <!-- <div class="pr_date">
                                {{ orders.created_at |date:'dd MMM y, h:mm a' }}
                            </div> -->
                        </div>
                        <!-- <div class="col-md-2 tr">
                            <div class="product_ss">
                                {{orders.order_status}}
                            </div>
                        </div> -->
                    </div>
                </div>

                <!-- <div class="myorderlist product_inner mt20">
                    <div  class="row order-area">
                        <div  class="col-md-6 tl col-xs-6">
                            <h5 class="oline_h">{{orders.created_at |date:'medium'}}</h5>
                        </div>
                         
                        <div  class="col-md-6 tr  col-xs-6">
                            <h5>{{total}}</h5>
                        </div>
                    </div>
                     
                </div> -->
            </div>
        </div>
    </div>
</section>