import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { EventPublishService } from 'src/app/providers/event-publish';
import { OtherService } from 'src/app/providers/other.service';
import { CancelOrderComponent } from '../cancel-order/cancel-order.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  orders: any = [];
  address: any;
  id: any;
  total:any;
  orderStatus = [{ id: 'ordered', name: 'Ordered',date:'' },
  { id: 'confirmed', name: 'Confirmed', date:'' },
  { id: 'packed', name: 'Packed',date:''},
  { id: 'shipped', name: 'Shipped', date:'' },
  { id: 'delivered', name: 'Delivered',date:''  }]

  constructor(public otherservice: OtherService, public route: ActivatedRoute, public dailog: MatDialog, public events: EventPublishService,
    public toaster: ToastrService, public router: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.route.params.subscribe(val => {
      this.id = val['id']
    })
    console.log(this.id)
    this.loadTrackOrders();
  }

  loadTrackOrders() {
    this.otherservice.trackOrder(this.id).subscribe(res => {
      let response: any = res;
      this.orders = response.data;
      this.orders.order_histories.forEach(element => {
        this.orderStatus.forEach(element2=>{
          if(element.status == element2.id){
           element2.date=element.created_at
          }
        })
      });
      console.log(this.orderStatus)
      this.address = response.data.shipping_address;
      console.log(this.address)
      this.getTotal();

    })
  }


  cancelOrder(id) {
    const dialogRef = this.dailog.open(CancelOrderComponent, {
      panelClass:"modal_sm",
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != undefined || res != null) {
        console.log(id, res.value)
        this.sendCancelRequest(id, res.value);
      }

    })
  }


  sendCancelRequest(id, result) {
    this.otherservice.cancelOrder(id, result).subscribe(async res => {
      console.log('res', res);
      let result: any = res;
      if (result.success == true) {
        this.toaster.success("your oreder cancelled")
        this.router.navigate(['my-orders'])
      }

    })
  }

  getTotal(){
    let total=0;
    this.orders.products.forEach(element => {
      total=total+element.product_price
      return total;
    });
    console.log(total)
   this.total=total
  }
}
