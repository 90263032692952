<section id="innerbanner">
	<div class="container">
		<div class="row">
			<div class="breadcrumb">
				<h1 class="wow fadeInUp">Products</h1>
				<span class="wow fadeInUp"><a [routerLink]="['/']" title="Home">Home</a> / <a
						title="Products">Products</a></span>
			</div>
		</div>
	</div>
</section>

<div class="clearfix"></div>


<!-- products -->
<section class="product_caro" id="product_caro" #target>
	<div class="container pb100" *ngIf='isHome'>
		<div class="row" *ngFor="let cat of catogaries">
			<div class="col-md-12 tc pt50 mb20">
				<span>Products</span>
				<h1 class="mb20">{{cat.catalog_category_name}}</h1>
				<p class="title_sub">{{cat.catalog_category_description}} </p>
			</div>
			<div class="clearfix"></div>
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-12 tc mb20">
						<!-- use active_tab class -->
						<span class="sub_title tab_box" (click)="getSub(cat,cat._id,'all')"
							[ngClass]="{'active_tab': active_id==0}">All</span>
						<span class="sub_title tab_box" *ngFor="let sub of cat.children" (click)="getSub(cat,sub._id)"
							[ngClass]="{'active_tab': active_id==sub._id}">{{sub.catalog_category_name}}</span>
					</div>
					<div class="col-md-12">
						<div class="product_slider_home">
							<div class="row_par">
								<div class="row">

									<div class="col-xs-6 col-md-3 isHome_height od_ev_padding"
										*ngFor="let products of cat ?.products?.data">
										<div class="product_div">
											<div class="product_inner">
												<div class="pr_image">
													<img src="{{products.image_url}}" class="img-responsive" alt="" />
												</div>
												<div class="pr_info_div">
													<div class="pr_info">
														<div class="row">
															<div class="col-md-7 col-xs-12">
																<div class="product_name">
																	<p class="naming-detail fc_font pt0 lh12 m0 lh12">Kriztle</p>
																	{{products.product_name}}
																</div>
															</div>
															<div class="col-md-5 col-xs-12 pr_md_price">
																<div class="product_name">
																	₹{{products.change_price|number:'1.2-2'}}
																</div>
															</div>
														</div>
														<div class="row">
															<div class="col-md-12 col-xs-12 tl pr0">
																<div class="product_dec">
																	{{products.product_description}}
																</div>
															</div>

															<div class="col-md-12 col-xs-12 hide_web">
																<div class="product_name">
																	₹{{products.change_price|number:'1.2-2'}}
																</div>
															</div>

														</div>
													</div>
													<div class="product_info">
														<div class="row">
															<div class="col-md-12 col-xs-12 tl pr0">
																<div class="product_info_small">Delivery Free</div>
																<div class="product_info_small2" (click)="priceDetails(products._id)">
																	Buy in multiple quantity and save more...
																</div>
															</div>

														</div>
													</div>
													<div class="pr_action_list">
														<div class="row">
															<div class="col-md-12 col-xs-12" *ngIf="!checkId(products._id)">
																<button *ngIf="products.product_availability!=0" type="button" class="add-cart-btn"
																	(click)="addToCart(products)">Add to Cart</button>
																<button *ngIf="products.product_availability==0" type="button"
																	class="add-cart-btn out_of_stock_btn">Out of Stock</button>
															</div>
															<div class="btn-group btn-align" role="group" align="center"
																*ngIf="checkId(products._id)">
																<button type="button" class="plus-btn" (click)="increaseProduct(products,'dec')"><span
																		class="ti-minus">-</span></button>
																<input type="number" readonly class="pr_in_count" [value]="products.qty">
																<button type="button" class="minus-btn" (click)="increaseProduct(products,'inc')"><span
																		class="ti-plus">+</span></button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div class="col-md-12 tc">
						<button class="next_btn_cart" (click)="goto()">Next <img src="assets/images/next-arrow.png"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- isDealer -->
	<div class="container pb100" *ngIf='isDealer'>
		<div class="row" *ngFor="let cat of catogaries">
			<div class="col-md-12 tc mb20">
				<h1 class="mb20">{{cat.catalog_category_name}}</h1>
				<p class="title_sub">{{cat.catalog_category_description}} </p>
			</div>
			<div class="clearfix"></div>
			<div class="col-md-12 mb40">
				<div class="row">
					<div class="col-md-12 tc">
						<!-- use active_tab class -->
						<span class="sub_title tab_box" (click)="getSub(cat,cat._id,'all')"
							[ngClass]="{'active_tab': active_id==0}">All</span>
						<span class="sub_title tab_box" *ngFor="let sub of cat.children" (click)="getSub(cat,sub._id)"
							[ngClass]="{'active_tab': active_id==sub._id}">{{sub.catalog_category_name}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="product_slider_home isDealer isDistributor base_grid">

					<div class="row_par">
						<div class="row">

							<div class="col-md-4" *ngFor='let products of cat.products?.data'>
								<div class="product_div">
									<div class="product_inner">
										<div class="row">
											<div class="col-md-6 col-xs-6">
												<div class="pr_image">
													<img [src]="products.image_url" class="img-responsive" alt="" />
												</div>
												<div class="pr_info_div">
													<div class="pr_info">
														<div class="product_name">
															<p class="naming-detail fc_font lh12 m0 pt0">Kriztle</p>
															{{ products.product_name }}
														</div>
														<div class="product_dec">
															{{ products.product_description }}
														</div>
													</div>
													<div class="product_info">
														<div class="row">
															<div class="col-md-12 col-xs-12 tl pr0">
																<div class="product_info_small">Delivery Free</div>
																<div class="product_info_small2" (click)="priceDetails(products._id)">
																	Buy in multiple quantity and save more...
																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
											<div class="col-md-6 col-xs-6">
												<div class="pr_action_list">
													<div class="row">
														<div class="col-md-12 col-xs-12">
															<div class="product_price">
																₹{{products.change_price|number:'1.2-2'}}
															</div>
														</div>
														<div class="col-md-12 col-xs-12 tl" *ngIf="!checkId(products._id)">
															<div class="btn-group btn-align" role="group">
																<button type="button" class="plus-btn" disabled><span class="ti-minus">-</span></button>
																<input type="number" readonly class="pr_in_count readonly" [value]="min_quantity">
																<button type="button" class="minus-btn" (click)="addToCart(products)"><span
																		class="ti-plus">+</span></button>
															</div>
														</div>
														
														<div class="col-md-12 col-xs-12 tl" *ngIf="checkId(products._id)">
															<div class="btn-group btn-align" role="group">
																<button type="button" class="plus-btn" (click)="increaseProduct(products,'dec')"><span
																		class="ti-minus">-</span></button>
																<input type="number" readonly class="pr_in_count" [value]="products.qty">
																<button type="button" class="minus-btn" (click)="increaseProduct(products,'inc')"><span
																		class="ti-plus">+</span></button>
															</div>
														</div>
														<!-- <div class="col-md-12">
																<span class="dealer_count">10,15,20,25</span>
															</div> -->
														<div class="col-md-12">
															<div class="dealer_amount_calc">
																<span class="dac_info">Amount Calculated</span>
																<span class="dac_price" *ngIf="!products.qty">{{products.change_price * 0
																	|number:'1.2-2'}}</span>
																<span class="dac_price" *ngIf="products.qty">{{products.change_price * products.qty
																	|number:'1.2-2'}}</span>
															</div>
														</div>
														<!-- <div class="col-md-12">
																<div class="distri_options">
																	<div class="dso_box_green">
																		<span class="dsob_text">Free Quality</span>
																		<span class="dso_price">05</span>
																	</div>
																	<div class="dso_box_gray">
																		<span class="dsob_text">Free Value</span>
																		<span class="dso_price">₹320</span>
																	</div>
																</div>
															</div> -->
														<div class="col-md-12">
															<div class="dlr_button_grp">
																<button class="dlr_add_cart" (click)="addToCart(products)"
																	[ngClass]="checkClass(products._id)">Add to Cart</button>
																<button class="dlr_buy_now" (click)="buyNow(products)">Buy Now</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12 tc">
				<button class="next_btn_cart" (click)="goto()">Next <img src="assets/images/next-arrow.png"></button>
			</div>
		</div>
	</div>
	<!-- isDistributor -->
	<div class="container pb100" *ngIf='isDistributor'>
		<div class="row" *ngFor="let cat of catogaries">
			<div class="col-md-12 tc mb20">
				<h1 class="mb20">{{cat.catalog_category_name}}</h1>
				<p class="title_sub">{{cat.catalog_category_description}} </p>
			</div>
			<div class="clearfix"></div>
			<div class="col-md-12 mb40">
				<div class="row">
					<div class="col-md-12 tc">
						<!-- use active_tab class -->
						<span class="sub_title tab_box" (click)="getSub(cat,cat._id,'all')"
							[ngClass]="{'active_tab': active_id==0}">All</span>
						<span class="sub_title tab_box" *ngFor="let sub of cat.children" (click)="getSub(cat,sub._id)"
							[ngClass]="{'active_tab': active_id==sub._id}">{{sub.catalog_category_name}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="product_slider_home isDealer isDistributor base_grid">

					<div class="row_par">
						<div class="row">

							<div class="col-md-4" *ngFor='let products of cat.products?.data'>
								<div class="product_div">
									<div class="product_inner">
										<div class="row">
											<div class="col-md-6 col-xs-6">
												<div class="pr_image">
													<img [src]="products.image_url" class="img-responsive" alt="" />
												</div>
												<div class="pr_info_div">
													<div class="pr_info">
														<div class="product_name">
															<p class="naming-detail fc_font lh12 m0 pt0">Kriztle</p>
															{{ products.product_name }}
														</div>
														<div class="product_dec">
															{{ products.product_description }}
														</div>
													</div>
													<div class="product_info">
														<div class="row">
															<div class="col-md-12 col-xs-12 tl pr0">
																<div class="product_info_small">Delivery Free</div>
																<div class="product_info_small2" (click)="priceDetails(products._id)">
																	Buy in multiple quantity and save more...
																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
											<div class="col-md-6 col-xs-6">
												<div class="pr_action_list">
													<div class="row">
														<div class="col-md-12 col-xs-12">
															<div class="product_price">
																₹{{products.change_price|number:'1.2-2'}}
															</div>
														</div>
														<div class="col-md-12 col-xs-12 tl" *ngIf="!checkId(products._id)">
															<button *ngIf="products.product_availability!=0" type="button" class="add-cart-btn"
																(click)="addToCart(products)">Add to Cart</button>
															<button *ngIf="products.product_availability==0" type="button"
																class="add-cart-btn out_of_stock_btn">Out of Stock</button>
														</div>
														<div class="col-md-12 col-xs-12 tl">
															<div class="btn-group btn-align" role="group" align="center"
																*ngIf="checkId(products._id)">
																<button type="button" class="plus-btn" (click)="increaseProduct(products,'dec')"><span
																		class="ti-minus">-</span></button>
																<input type="number" readonly class="pr_in_count" [value]="products.qty">
																<button type="button" class="minus-btn" (click)="increaseProduct(products,'inc')"><span
																		class="ti-plus">+</span></button>
															</div>
														</div>
														<!-- <div class="col-md-12">
																<span class="dealer_count">10,15,20,25</span>
															</div> -->
														<div class="col-md-12">
															<div class="dealer_amount_calc">
																<span class="dac_info">Amount Calculated</span>
																<span class="dac_price" *ngIf="!products.qty">{{products.change_price * 0
																	|number:'1.2-2'}}</span>
																<span class="dac_price" *ngIf="products.qty">{{products.change_price * products.qty
																	|number:'1.2-2'}}</span>
															</div>
														</div>
														<div class="col-md-12">
															<div class="distri_options">
																<div class="dso_box_green">
																	<span class="dsob_text">Free Quantity</span>
																	<span class="dso_price">{{min_order_amount/products.change_price
																		|number:'1.0-0'}}</span>
																</div>
																<div class="dso_box_gray">
																	<span class="dsob_text">Free Value</span>
																	<span class="dso_price">₹{{min_order_amount |number:'1.2-2'}}</span>
																</div>
															</div>
														</div>
														<div class="col-md-12">
															<div class="dlr_button_grp">
																<button class="dlr_add_cart" (click)="addToCart(products)"
																	[ngClass]="checkClass(products._id)">Add to Cart</button>
																<button class="dlr_buy_now" (click)="buyNow(products)">Buy Now</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12 tc">
				<button class="next_btn_cart" (click)="goto()">Next <img src="assets/images/next-arrow.png"></button>
			</div>
		</div>
	</div>
</section>



<!-- sp-ad banner -->
<section class="Ads-banner">
	<div class="container">
		<div class="row">
			<div class="col-md-6 adbanner-content pull-right">
				<h1>Sourced From the Meadow of Nature</h1>
				<hr>
				<p>We are inspired by nature and we have most sophisticated ways to bottle nature’s
					purity without compromising its quality. Sourced from the purest springs of God’s own country we
					provide utmost care to maintain consistent quality of its variety of bottled water.</p>
			</div>
		</div>
	</div>
</section>
<!--// sp-ad banner -->

<div class="clearfix"></div>

<!-- process -->
<section class="process">
	<div class="container">
		<div class="row">
			<div class="col-md-12 title-content">
				<h1 class="wow fadeInUp">Purification Process</h1>
				<p class="wow fadeInUp sp">Kriztle always emphasis to maintain consistent quality in each strides that
					is taken. To ensure quality and natural purity, Kriztle implements numerous quality assurance
					processes before bottling. </p>
			</div>
			<img src="assets/images/process.jpg" class="img-responsive wow fadeInUp" alt="process" />
		</div>
	</div>
</section>
<!--// process -->


<div class="clearfix"></div>
<!-- @endsection -->