import { AddAddressComponent } from './views/add-address/add-address.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { ProfileComponent } from './views/profile/profile.component';
import { MyOrdersComponent } from './views/my-orders/my-orders.component';
import { LoginComponent } from './views/login/login.component';
import { CartComponent } from './views/cart/cart.component';
import { NgModule, Injectable, Inject } from '@angular/core';
import { Routes, RouterModule, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BasicLayoutComponent } from './components/common/layout/basic-layout/basic-layout.component';
import { HomeComponent } from './views/home/home.component';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { Observable } from 'rxjs';
import { TopnavbarComponent } from './components/common/topnavbar/topnavbar.component';
import { AboutComponent } from './views/about/about.component';
import { ProductsComponent } from './views/products/products.component';
import { ContactComponent } from './views/contact/contact.component';
import { OrderCompletedComponent } from './views/order-completed/order-completed.component';
import { OrderDetailsComponent } from './views/order-details/order-details.component';
import { AuthGuard } from './auth/auth.guard';
import { AddProfileComponent } from './views/add-profile/add-profile.component';
import { SchemeComponent } from './views/scheme/scheme.component';
import { TermsComponent } from './views/terms/terms.component';

@Injectable({ providedIn: 'root' })
export class LoginActivate implements CanActivate {
  constructor( @Inject(LOCAL_STORAGE) private storage: StorageService,
    ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    
    return true;
  }
}

@Injectable()
export class CheckOutActivate implements CanActivate {
   cart:any;
  constructor(public router:Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

     this.cart=JSON.parse(localStorage.getItem('cart'));
    if (this.cart==null) {
      this.router.navigate(['/home'])
      return;
    }

    return true;
  }
}


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', },
  {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: 'about', component: AboutComponent, pathMatch: 'full' },
      { path: 'products', component: ProductsComponent, pathMatch: 'full' },
      { path: 'contact', component: ContactComponent, pathMatch: 'full' },
      { path: 'cart', component: CartComponent, pathMatch: 'full' },
      { path: 'scheme', component: SchemeComponent, pathMatch: 'full' ,canActivate: [AuthGuard]},
      // { path: 'add-profile', component: AddProfileComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent, pathMatch: 'full' },
      { path: 'my-orders', component: MyOrdersComponent, pathMatch: 'full',canActivate: [AuthGuard] },
      { path: 'profile', component: ProfileComponent, pathMatch: 'full',canActivate: [AuthGuard] },
      { path: 'checkout', component: CheckoutComponent, pathMatch: 'full',canActivate: [AuthGuard,CheckOutActivate]},
      // { path: 'add-address', component: AddAddressComponent, pathMatch: 'full',canActivate: [AuthGuard] },
      // { path: 'order-completed', component: OrderCompletedComponent, pathMatch: 'full',canActivate: [AuthGuard] },
      { path: 'order-details/:id', component: OrderDetailsComponent, pathMatch: 'full',canActivate: [AuthGuard] },
      { path: 'terms', component: TermsComponent, pathMatch: 'full' },
      {
        path:'terms', component: TermsComponent
      },
      {
        path:'privacy', component: TermsComponent
      },
      {
        path:'refund-policy', component: TermsComponent
      },
      {
        path:'shipping-policy', component: TermsComponent
      },
    
]
  },
  { path: '**', redirectTo: 'home' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', enableTracing: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
