import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-completed',
  templateUrl: './order-completed.component.html',
  styleUrls: ['./order-completed.component.css']
})
export class OrderCompletedComponent implements OnInit {

  id:any;

  constructor(public dailog:MatDialog,public dialogRef: MatDialogRef<OrderCompletedComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
  public router:Router,public zone:NgZone) { 
    if(data !=null){
     this.id=data.id
     console.log(this.id)
    }
  }

  ngOnInit() {
  }

  navigate(){
    this.zone.run(()=>{
      console.log("llllllllllllll")
      this.router.navigate(['/order-details',this.id])
      this.dialogRef.close()
    })
  }

}
