import { Component, OnInit } from '@angular/core';
import { OtherService } from '../../providers/other.service';

@Component({
  selector: 'app-scheme',
  templateUrl: './scheme.component.html',
  styleUrls: ['./scheme.component.css']
})
export class SchemeComponent implements OnInit {

  points:any;
  ending_date:any;
  scheme_reccurance:any;
  plan:any;
  total_plan_point:any;

  constructor(public other:OtherService) { }

  ngOnInit(): void {
    this.getSchemes();
  }


  getSchemes(){
    this.total_plan_point=0
    this.other.getSchemes().subscribe(res=>{
      console.log("res",res);
      let result:any=res;
      if (result.success) {
      this.points=result.data.points;
      this.ending_date=result.data.scheme_valid_to;
      this.scheme_reccurance=result.data.scheme_reccurance;
      if (result.data.plan) {
        this.plan=result.data.plan;
        console.log("length",this.plan.length);
        // let i:any=this.plan.length;
        // this.plan.push(this.plan[i]+50);
        this.plan=result.data.plan;
        this.plan.forEach(element => {
        this.total_plan_point=this.total_plan_point+element.min_point
        })
      }else{
        this.plan=false;
      }
      console.log("plan",this.plan);
      
      }

    })
  }

  getWidth(){
    console.log(this.points)
    var last_element = this.plan[this.plan.length - 1];
    console.log(last_element)
    var style=(this.points/last_element.min_point)*100
    console.log(style)
    return style
  }

  // getWidth(){
  //   console.log(this.points)
  //   var last_element = this.plan[this.plan.length - 1];
  //   console.log(last_element)
  //   var style=(this.points/last_element.min_point)*100
  //   console.log(style)
  //   return style
  // }
}
