import { Router } from '@angular/router';
import { UserService } from './../../providers/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { OtherService } from '../../providers/other.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddProfileComponent } from '../add-profile/add-profile.component';
import { EventPublishService } from '../../providers/event-publish';
import { CartService } from '../../providers/cart.service';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  otp:any;
  submitted:boolean=true;
  mobile_number:any;
  otpresult:any;
  isOtpSuccess:boolean=false;
  otpSubmitted:boolean=false;
  user_details:any;
  pattern1 =  "^[0-9_-]{10,10}"
  url:any;
  otp_invalid:boolean=false;
  invalid_otp:boolean = false;
  is_place:boolean=false;
  

  constructor(public userservice:UserService, public router:Router,public other:OtherService,public dailog:MatDialog,
    public events:EventPublishService,public cart:CartService,public dailogRef:MatDialogRef<LoginComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
    public toaster:ToastrService,public formBuilder:FormBuilder) { 
      if(data !=null){
        this.url=data.url
        console.log(this.url)
      }
      console.log(data)
    }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      mobile:['',[Validators.required,Validators.pattern(this.pattern1)]]
    })
  }

  submit(data,value){
    if (this.loginForm.invalid) {
      this.submitted = false;
    }
    else{
      this.submitted = true;
      console.log(data,value)
      this.mobile_number=data;
      this.userservice.getOtp(data).subscribe(res=>{
        console.log("res otp",res);
        this.toaster.success("OTP successfully sent to" ,this.mobile_number.mobile)
        let otpresult:any=res;
        if (otpresult.success==true) {
          this.isOtpSuccess=true;
          
        }
        else{
          this.isOtpSuccess=false;
        }
      })

    }
  }

  get acf() { return this.loginForm.controls; }

  submitOtp(){
    this.is_place=false;
    if (this.otp==null ||undefined && this.otp.length<4) {
      this.otpSubmitted=true;
    }
    else{
      this.otpSubmitted=false;
      console.log("otp",this.otp);
      this.userservice.verifyOtp(this.mobile_number,this.otp).subscribe(res=>{
        if(res['success']==true){
          this.is_place=true;
          console.log("submit otp res",res);
          let result:any=res;
          this.userservice.isuser=true;
          this.userservice.hasLoggedIn=true;
          this.userservice.api_token=result.api_token;
          this.userservice.user_details=result;
          this.userservice.userSet();
          this.other.getProfile().subscribe(res=>{
  
  
            let result:any=res;
            this.user_details=result.data;
            console.log("me profile res",result);
            this.userservice.user_details=result;
            this.cart.loginclearcart(result)
            this.userservice.userSet();
            // this.otherservice.getLoginCalculate();
  
            if (result.data.customer_name) {
              this.events.publishSomeData("login")
              console.log("kkkkkkkkkkkkkk")
              this.dailogRef.close()
              if(this.url !=null){
                this.toaster.success("You are logged in successfully")
                this.router.navigate([this.url]);
              }else{
                this.toaster.success("You are logged in successfully")
                this.router.navigate(['home']);
              }
     
              // this.location.back();
            }else {
              console.log("llllllllll")
              this.dailogRef.close()
              const dialogRef = this.dailog.open(AddProfileComponent, {
              })
    
              dialogRef.afterClosed().subscribe(result=> {
               this.router.navigate(['home'])
              });
  
            }
        })
        }
        
    }, err => {
      console.log("kkkkkkkkkkk")
      this.otp_invalid=true;
 
      // this.toaster.warning("Invalid OTP")
      // return false;
    }
    )
  }
}
  onOtpChange(otp) {
    this.otp=otp;
  }

}
