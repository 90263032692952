<section id="innerbanner">
    <div class="container">
        <div class="row">
            <div class="breadcrumb">
                <h1 class="">Contact Us</h1>
                <span><a [routerLink]="['/']" title="Home">Home</a> / <a title="Contact">Contact</a></span>
            </div>
        </div>
    </div>
</section>

<div class="clearfix"></div>
<!-- contact -->
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <div class="contact-address">
                    <p class="">Shan International, Shan Plaza, 10/364 E, Ooty Road, Cherani - 676 123, Kerala State - INDIA
                    </p>
                    <hr>
                    <span class="">
                        <span>
                            <img src="assets/images/phone.png" class="img-responsive" alt="Phone">
                        </span>
                        <a href="tel:+919207570570" title="tel:+919207570570">+91 9207 570 570,</a>
                        <a href="tel:+919747777140" title="tel:+919747777140">+91 9747 777 140</a>
                        <p class="clearfix"></p>
                        <span>
                            <img src="assets/images/mail.png" class="img-responsive" alt="Mail">
                        </span>
                        <a href="mailto:kriztleglobal@gmail.com" title="Mail To : kriztleglobal@gmail.com">kriztleglobal@gmail.com</a>
                    </span>
                </div>
                
            </div>
            
            <div class="col-md-7 formarea">
                <div class="row">
                    <form [formGroup]="contactForm" name="contact_form" id="contact_form" method="POST" action="/contactform"
                        role="form">
                        <div class="col-md-6">
                            <input type="text" class="formfill profile-inp2 " formControlName="name" name="name" required="required"
                                placeholder="Name*">
                            <div *ngIf="submitted && acf.name.errors">
                                <div class="form-error-msg" *ngIf="acf.name.errors.required">*Name is
                                    required</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="formfill profile-inp2 " formControlName="email" name="email"
                                required="required" placeholder="Mail*">
                            <div *ngIf="submitted && acf.email.errors">
                                <div class="form-error-msg" *ngIf="acf.email.errors.required">*Email is required</div>
                                <div class="form-error-msg" *ngIf="acf.email.errors.pattern">Enter Valid email</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="formfill profile-inp2 " formControlName="phone" name="phone"
                                required="required" placeholder="Phone*">
                            <div *ngIf="submitted && acf.phone.errors">
                                <div class="form-error-msg" *ngIf="acf.phone.errors.required">*Mobile Number is required</div>
                                <div class="form-error-msg" *ngIf="acf.phone.errors.pattern || acf.phone.errors.minlength">
                                    Enter Valid mobile number</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="formfill profile-inp2 " formControlName="subject" name="subject"
                                required="required" placeholder="Subject*">
                            <div *ngIf="submitted && acf.subject.errors">
                                <div class="form-error-msg" *ngIf="acf.subject.errors.required">*Subject is
                                    required</div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <textarea class="formfill profile-inp2 " name="message" formControlName="message" required="required"
                                 placeholder="Message*"></textarea>
                            <div *ngIf="submitted && acf.message.errors">
                                <div class="form-error-msg" *ngIf="acf.message.errors.required">*Message is
                                    required</div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <input type="submit" (click)="sendData()" class="send-btn " name="send" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="clearfix"></div>