<section class="modal_section_div">
  <button mat-dialog-close class="close_md_date">
    <img src="assets/images/close.svg" class="rem_product pointer">
  </button>
  <div class="container-fluid">
    <div class="row">
      <div class="modal_inner addreS_section" *ngIf='id=="date"'>
        <div class="md_hedear">
          <h6 class="h6">Choose Date</h6>
          <div class="cal_div" *ngFor='let month of common.DateArray'>
            <div class="month_day">
              <div class="month_name">
                <span>{{ month.month }}</span>
              </div>
            </div>
            <div class="head_day">
              <div class="day_inner">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Wed</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div>
            </div>
            <div class="date">
              <ng-container *ngFor='let date of month.date;let i=index'>
                <ng-container *ngFor='let free of common.getVacantDays(date.date._d)'>
                    <span class="date_s" class="day_box disabled" *ngIf='i == 0'>
                      <span></span>
                    </span>
              
                </ng-container>
                <span class="date_d" *ngIf='date.availability == true'  [ngClass]="{'active_date':i==0 && month.month == currentMonth}">
                  <span [ngClass]="{'active': isSameDate(date.date._d)}"
                    (click)="selectedDate('time',date.date._d)">{{date.date._d | date:'d' }}</span>
                </span>
                <span class="date_s" class="day_box disabled" *ngIf='date.availability == false'>
                  <span>{{ date.date._d | date:'d' }}</span>
                </span>
              </ng-container>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal_inner addreS_section" *ngIf='id=="time"'>
        <div class="md_hedear">
          <div class="cal_div">
            <h6>Choose Time</h6>
            <div class="time_div" *ngIf="common.SelectedDate != null">
              <ng-container *ngFor='let time of common.selectedTimeArray'>
                <span class="time_ds" [ngClass]="{'active-time': isSameTime(time._d)}" (click)="chooseTime(time)"> {{
                  time |
                  date:'h:mm a' }}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>