import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OtherService } from './../../providers/other.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/providers/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
declare var google;

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.css']
})
export class AddAddressComponent implements OnInit {

  addressForm:FormGroup;
  today:any = new Date().toISOString().split('T')[0];
  cities:any;
  neighbourhood:any;
  latitude:any;
  submitted:boolean=false;
  longitude:any;
  lat:any;
  nieborhoodId:any;
  zoom:any;
  lng:any;
  id:any;
  streetAddress:any;
  userDetails:any=[];
  profileData:any=[];
  user_details:any;
  postcode:any;
  time:any=['05:00 am','07:00 am','09:00 am','11:00 am','1:00 pm','03:00 pm','05:00 pm','07:00 pm'];

  constructor(public otherservice:OtherService, private toastr:ToastrService, public router:Router,public user:UserService,
    public dailog:MatDialog,public dialogRef: MatDialogRef<AddAddressComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    if (navigator)
    {
    navigator.geolocation.getCurrentPosition( pos => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;      
      });
    }
   }

  ngOnInit() {
    this.user_details= JSON.parse(localStorage.getItem("user_details"));
    this.setCurrentLocation()
    this.addressForm = new FormGroup({
      name: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
      email: new FormControl(''),
      address: new FormControl('', Validators.required),
      muncipality: new FormControl('', Validators.required),
      pincode: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      // village: new FormControl('', Validators.required),
      // date: new FormControl('',),
      // time: new FormControl('',),
    });
    this.getProfile();
    this.loadCities();
  }

  getProfile(){
    this.otherservice.getProfile().subscribe(resp=>{
      this.profileData=resp['data']
      console.log(this.profileData.customer_mobile)
      if(this.profileData.customer_mobile !=null){
        this.addressForm.get('number').patchValue(this.profileData.customer_mobile );
      }
      if(this.profileData.customer_name !=null){
        this.addressForm.get('name').patchValue(this.profileData.customer_name );
      }
      if(this.profileData.customer_email !=null){
        this.addressForm.get('email').patchValue(this.profileData.customer_email );
      }
    })

  }

  loadCities(){
    this.otherservice.getCities().subscribe(res=>{
      let result:any=res;
      this.cities=result.data;
    })
  }

  loadNeighborhood(event){
    console.log(event.target.value)
    this.id = event.target.value;
   this.otherservice.getNeighborhood(this.id).subscribe(res=>{
     let result:any=res;
     this.neighbourhood=result.data;
     
   })
  }

  getNighberhood(event){
  this.nieborhoodId=event.target.value;
  console.log(this.nieborhoodId)
  }

  chooseLocation(event){
    this.lat=event.coords.lat;
    this.lng=event.coords.lng;
    this.latitude=event.coords.lat;
    this.longitude=event.coords.lng;
    this.getCurrentAddress();
    
  }

  private setCurrentLocation() {
   
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.getCurrentAddress();
        this.zoom = 15;
      });
    }


  }

  getCurrentAddress(){
    var temp:any
    var streetAddress:any;
    let geocoder = new google.maps.Geocoder();
     let latlng = {lat: this.latitude, lng: this.longitude};
     geocoder.geocode({'location': latlng}, function(results) {
      this.address=results[0].address_components;
      console.log(results)
      temp = this.address.find(x=> x.types[0] == 'postal_code').long_name
      streetAddress=results[0].formatted_address;
      console.log(temp,'iiiiiiiiiiiiiiiiiii') 
     });  
     setTimeout(() => {
      console.log(temp,'iiiiiiiiiiiiiiiiiii')
      this.postcode = temp
      this.streetAddress=streetAddress;
      console.log(this.postcode)
      console.log(this.streetAddress)
      if(this.postcode !=null){
        
      }
      if(this.streetAddress !=null){
        // this.addressForm.get('location').patchValue(this.streetAddress)
      }
     }, 1000);
    }

    get acf() { return this.addressForm.controls; }

  addAddress(){
    this.submitted=true;
  if (this.addressForm.valid) {

      var data={
        address_loc_lat:this.latitude,
        address_loc_lon:this.longitude,
        address_location_id:+this.id,
        address_mobile:this.acf.number.value,
        address_name:this.acf.name.value,
        address_pincode:this.acf.pincode.value,
        // village:this.acf.village.value,
        muncipality:this.acf.muncipality.value,
        address_street_address:this.acf.address.value,
        customer_id:this.user_details._id,
        address_neighborhood_id:+this.nieborhoodId,
        id:null
      }

      this.otherservice.saveAddress(data).subscribe(res =>{
        let result:any=res;
        if (result.success==true) {
         this.dialogRef.close()
        }else if(!result.success){
          this.toastr.error('address not added. try again!!')
        }
      })
    }
    
  }

}
