import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OtherService } from 'src/app/providers/other.service';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { EventPublishService } from 'src/app/providers/event-publish';
import { CartService } from 'src/app/providers/cart.service';
import { CommonProvider } from 'src/app/providers/common';
import { LoginComponent } from '../login/login.component';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  cart_items: any;
  product_price: any = [];
  product_prices: any = [];
  // tax_amount:any=[];
  // tax_amounts:any=[];
  // total_tax:any=0;
  customer_group_name:any;
  total_price: any = 0;
  total: any = [];
  prod_qty: any = [];
  qty: any = [];
  user_details: any;
  quantity: any;
  api_token:any;

  constructor(public router: Router, public toatr: ToastrService, public otherservice: OtherService,
    public dialog: MatDialog, public events: EventPublishService, public cart: CartService, public general: CommonProvider,
    private decimalPipe: DecimalPipe,public dailog:MatDialog) {

    events.getObservable().subscribe((data) => {
      if (data == 'cart:updated') {
        console.log(data)
        this.cart_items = JSON.parse(localStorage.getItem('cart'));
        console.log(this.cart_items)
        if (this.cart_items) {
          this.cart_items.forEach(element => {
            element.change_price=element.product_price
            element.bulk_prices.forEach(element1 => {
              // if (this.user_details && this.user_details.data.customer_group_id != null) {
                // if (this.user_details.data.customer_group_id == element1.customer_group_id) {
                  if (element.qty >= element1.min_quantity && element.change_price > element1.product_price) {
                    element.change_price = element1.product_price
                    console.log(element.product_price)
                  }
                  // if(element.qty < element1.min_quantity){
                  //   console.log(element.product_price)
                  //   element.product_price=element.product_og_price
                  // }
                // }
              // }
              console.log(this.cart_items)
            });
          });
        }
      }

    });
  }

  ngOnInit() {
    this.user_details = JSON.parse(localStorage.getItem('user_details'));
    if (this.user_details !=null) {
      if(this.user_details.data.customer_group !=null){
        this.customer_group_name=this.user_details.data.customer_group.customer_group_name;
        this.customer_group_name=this.user_details.data.customer_group.customer_group_name;
        console.log(this.customer_group_name)
      }
    }
    this.cart_items = JSON.parse(localStorage.getItem('cart'));
    console.log(this.cart_items)
    if (this.cart_items != null) {
      this.cart_items.forEach(element => {
        element.bulk_prices.forEach(element1 => {
          console.log(this.user_details)
          // if (this.user_details && this.user_details.data.customer_group_id != null) {
            // if (this.user_details.data.customer_group_id == element1.customer_group_id) {
              if (element.qty >= element1.min_quantity && element.change_price > element1.product_price) {
                console.log("222222222222222")
                element.change_price = element1.product_price
                console.log(element.product_price)
              }
              // if(element.qty < element1.min_quantity){
              //   console.log(element.product_price)
              //   element.product_price=element.product_og_price
              // }
            // }
          // }
          console.log(this.cart_items)
        });
      });
    }
    if (this.cart_items == null || this.cart_items == undefined) {
      this.cart_items = [];
    }
    this.getTotalAmount();
  }



  // updateQuantity(opt,prod){
  //   const index = this.cart_items.indexOf(prod);
  //   this.cart_items = JSON.parse(localStorage.getItem('cart'));
  //   if (opt == 'inc') {
  //     this.cart_items[index].qty=this.cart_items[index].qty + 5;
  //     localStorage.setItem('cart',JSON.stringify(this.cart_items));
  //     this.getTotalAmount();
  //   }

  //   if (opt == 'dec') {
  //     if (this.cart_items[index].qty > 1) {
  //       this.cart_items[index].qty=this.cart_items[index].qty - 5;
  //       localStorage.setItem('cart',JSON.stringify(this.cart_items));
  //       this.getTotalAmount();
  //     }
  //   } 

  // }

  increaseProduct(prod,value){
    console.log(prod)
   this.cart_items.forEach(element => {
     element.change_price=element.product_price
     if(element._id==prod._id){
       if(value =='inc'){
        // this.catogaries.forEach(element => {
        //   element.products.data.forEach(element2 => {
        //     if(element2._id ==prod._id){
        //       element2.qty +=5
        //     }
        //   });
        // });
        if(this.customer_group_name=='dealer'){
          this.quantity=5
        }
        if(this.customer_group_name=='distributors'){
          this.quantity=25
        }
        if(this.customer_group_name!=='dealer' && this.customer_group_name!=='distributors'){
          this.quantity=1
        }
       
       }
       if(value=='dec'){
        // this.catogaries.forEach(element => {
        //   element.products.data.forEach(element2 => {
        //     if(element2._id ==prod._id){
        //       console.log("11111111111111111")
        //       element2.qty -=5
        //     }
        //   });
        // });
        this.quantity=0
        if(this.customer_group_name=='dealer'){
          this.quantity -=5
        }
        if(this.customer_group_name=='distributors'){
          this.quantity -=25
        }
        if(this.customer_group_name!=='dealer' && this.customer_group_name!=='distributors'){
          this.quantity -=1
        }
       }
       
       if(prod.bulk_prices.length > 0){
         prod.bulk_prices.forEach(element1 => {
          //  if(this.user_details && this.user_details.customer_group_id !=null){
          //  if(this.user_details.customer_group_id==element1.customer_group_id){
             console.log(prod.qty,element1.min_quantity)
             if(!element.change_price){
            if(prod.qty >= element1.min_quantity && prod.product_price > element1.product_price){
              
                console.log("666666666666")
                this.updatePrice(prod,element1.product_price)
              }
           
             }else{
              if(element.qty >= element1.min_quantity && element.change_price > element1.product_price){
                console.log("00000000000")
                this.updatePrice(prod,element1.product_price)
              }
            }
            //  if(prod.qty < element1.min_quantity && prod.product_price > element1.product_price){
            //    this.updatePrice(prod,prod.product_og_price)
            //  }
          //  }
          // }
         });
       }
     }
   });
   console.log(this.quantity)
   this.cart.updateProduct(this.quantity,prod)
  }

  updatePrice(data, price) {
    console.log(data, price)
    this.cart_items.forEach(element => {
      if (element._id == data._id) {
        console.log("7777777777777")
        element.change_price = price;
        console.log(element.product_price)
      }
    });
  }

  removeItem(prod) {
    const index = this.cart_items.indexOf(prod);
    console.log(index)
    this.cart_items.splice(index,1);
    console.log(this.cart)
    let cart=localStorage.setItem('cart',JSON.stringify(this.cart_items));
    console.log(cart)
    this.cart.loadCart();
    this.cart.getTotalQuantity();
  }

  continue() {
    let isuser: any = localStorage.getItem('user');
    if (isuser == false) {
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass:"modal_sm"
      })
  
      dialogRef.afterClosed();
    }
    else {
      this.router.navigate(['/checkout']);
    }

  }

  priceDetails(id) {
    this.api_token = localStorage.getItem('api_token')
    if (this.api_token != null) {
    var i = this.cart_items.findIndex(o => o._id == id);
    var index = this.cart_items.findIndex(o => o._id == id);
    let dialogRef = this.dialog.open(DialogComponent, { panelClass:"modal_sm", data: { id: id, bulk_price: this.cart_items[i].bulk_prices } })
    let result: any;
    dialogRef.afterClosed().subscribe(res => {
      result = res;
      if (result != undefined && result != '') {
        this.cart_items[index].qty = result.max_qty;
        this.cart_items[i].change_price = result.product_price;
        localStorage.setItem('cart', JSON.stringify(this.cart_items));
        this.cart.getTotalQuantity();
      }
    })
  }else{
    const dialogRef = this.dailog.open(LoginComponent, {
      panelClass:"modal_sm"
    })

    dialogRef.afterClosed();
  }
  }


  getTotalAmount() {
    this.total = 0;
    console.log(this.cart_items)
    if (this.cart_items != null) {
      this.cart_items.forEach(element => {
        //   element.bulk_prices.forEach(element1 => {
        //     if(this.user_details &&this.user_details.customer_group_id !=null){
        //     if(this.user_details.customer_group_id==element1.customer_group_id){
        //     if(element.qty >= element1.min_quantity){
        //      element.product_price=element1.product_price
        //     }
        //   }
        // }
        //   });
        this.total = this.total + (element.qty * element.change_price)
      });
      return this.decimalPipe.transform(this.total, '1.2-2');
    }
  }


  getTotalQty(){
    this.total = 0;
    console.log(this.cart_items)
    if (this.cart_items != null) {
      this.cart_items.forEach(element => {
        //   element.bulk_prices.forEach(element1 => {
        //     if(this.user_details &&this.user_details.customer_group_id !=null){
        //     if(this.user_details.customer_group_id==element1.customer_group_id){
        //     if(element.qty >= element1.min_quantity){
        //      element.product_price=element1.product_price
        //     }
        //   }
        // }
        //   });
        this.total = this.total + element.qty 
      });
      return this.total;
    }
  }
}
