import { Component, OnInit,Inject } from '@angular/core';
import * as moment from 'moment';
import { CommonProvider } from 'src/app/providers/common';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css']
})
export class DateTimePickerComponent implements OnInit {

  id:any
  todayDate = new Date()
  currentTime = new Date()
  DateArray:any=[]
  timeArray:any=[]
  isSelect:boolean =false;
  currentMonth:any;

  constructor(public common:CommonProvider,public dialogRef: MatDialogRef<DateTimePickerComponent>,@Inject(MAT_DIALOG_DATA) public data: any,public datepipe:DatePipe) { 
    if(this.common.schedule_delivery_available_time != null){
      this.common.dateTimeSettings = this.common.schedule_delivery_available_time
    }
    this.id = data.id;
    console.log(this.id)
    this.common.getDays()
    var startTime = moment().set({'hour': 1,'minute': 0});
    console.log(startTime)
    
    for(let i=0;i<12;i++){
      this.timeArray.push(moment(startTime).format('HH:mm'))
      var time = moment(startTime).add(120 , 'minute')
      startTime = time
    }

  
    console.log("common.DateArray",common.selectedTimeArray);
  }

  ngOnInit(): void {
    console.log(this.common.DateArray)
    this.currentMonth=this.datepipe.transform(new Date(), 'MMMM');
    console.log(this.currentMonth)
  }


  dismiss(){
    this.dialogRef.close({
      'id':this.id,
      'time':this.common.selectedTime,
      'date':this.common.SelectedDate,
      'isSelect':this.isSelect
    })
  }


  selectedDate(value,date){
    this.id = value
    this.common.SelectedDate = date
    this.common.selectedTimeArray = []
    this.common.isInside = false
    console.log(this.timeArray)
    this.timeArray.forEach(element => {
      this.common.isValidTime(element)
    });
    console.log(this.common.selectedTimeArray)
  }

  chooseTime(time){
    this.isSelect = true
    this.common.selectedTime = time._d
    this.dismiss()
  }

  isSameDate(date){
    if(this.common.SelectedDate != null){
      if(moment(date).isSame(moment(this.common.SelectedDate),'day')){
        return true
      }
    }
  }

  isSameTime(choosedTime){
    if(moment(choosedTime).format('HH:mm') == moment(this.common.selectedTime).format('HH:mm')){
      return true
    }
  }
}
