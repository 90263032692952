import { OtherService } from './../../providers/other.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { CommonProvider } from 'src/app/providers/common';
import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';
import { data } from 'jquery';
import * as moment from 'moment';
import { ThisReceiver } from '@angular/compiler';
import { CartService } from 'src/app/providers/cart.service';
import { EventPublishService } from 'src/app/providers/event-publish';
import { ExternalLibraryService } from '../../providers/util';
import { ChangeDetectorRef } from '@angular/core';
import { AddAddressComponent } from '../add-address/add-address.component';
import { NgxSpinnerService } from "ngx-spinner";
import { OrderCompletedComponent } from '../order-completed/order-completed.component';
declare let Razorpay: any;
declare let RazorpayCheckout: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  razorPayOptions={
    "key":'',
    "amount":'',
    "currency":"INR",
    "name":'',
    'order_id':'',
    "description":"Skartz Payment",
    "handler":(resp)=>{
      console.log(resp);
    }

  }

  cart_items:any;
  address:any=[];
  select_address:any;
  payment_method:any;
  delivery_place:any;
  isCod:boolean=false;
  product_price:any=[];
  product_prices:any=[];
  // tax_amount:any=[];
  // tax_amounts:any=[];
  // total_tax:any=0;
  total_price:any=0;
  total:any=[];
  is_place:boolean=false;
  prod_qty:any=[];
  todayDate = new Date()
  hasScheduledDeliveryDate:boolean =false;
  hasScheduledDeliveryTime:boolean =false;
  scheduled_time:any = null;
  selected_datetime:any;
  isDate:boolean=false;
  qty:any=[];
  imageChangedEvent:any;
  invoiceImage:any=null
  timeArray:any=[];
  isProcessing:boolean=false;
  razorpayResponse;
  imageSize:any;
  dPlace:boolean=false;
  d_address:boolean=false;
  paymentDetails:any=[];
  location_id:any;
  delivery_charge:any;
  imgURL:any;
  d_place:boolean=false;
  is_payment:boolean=false;
  is_date:boolean=false;
  is_
  user_details:any=[];
  
  customer_group_name:any;
  calculated_Total:any;
  constructor(public otherservice:OtherService, public router:Router,public common:CommonProvider,private toastr:ToastrService,
    public dialog:MatDialog,public cart:CartService,public events:EventPublishService,public razorpayService: ExternalLibraryService,
    public zone:NgZone,public cd:ChangeDetectorRef,private spinner: NgxSpinnerService) { 
      this.user_details=JSON.parse(localStorage.getItem('user_details'));
      console.log(this.user_details)
      
      if (this.user_details !=null) {
        if(this.user_details.data.customer_group !=null){
          console.log("ggggggggggggggggggggggg")
       this.customer_group_name=this.user_details.data.customer_group.customer_group_name;
       console.log(this.customer_group_name)
        }
      
        // console.log(user_details.customer_group)
      }

    }

  ngOnInit() {
    console.log(this.common.SelectedDate)
    this.razorpayService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
    .subscribe();
    this.common.selectedTime=null
    this.common.SelectedDate=null
    console.log(this.common.max_scheduled_days)
    this.cart_items = JSON.parse(localStorage.getItem('cart'));
    if (this.cart_items==null||this.cart_items==undefined) {
      this.cart_items=[];
    }
    this.getAddress();
    this.getDate();
    if(this.common.instant_delivery_available_time != null){
      this.common.dateTimeSettings = this.common.instant_delivery_available_time
    }
    if(this.common.max_scheduled_days > 0){
      console.log("lllllllllllllllllllllllllllllllll")
      this.common.maxScheduledDays = this.common.max_scheduled_days
    }
  
    let day = moment(this.todayDate).format('ddd')
    if(this.common.SelectedDate != null && this.common.selectedTime != null){
      this.scheduled_time = (moment(this.common.SelectedDate).format('YYYY-MM-DD') + 'T' +  moment(this.common.selectedTime).format('HH:mm:ss')+'+0530')
    }
 this.getTotalAmount();
 

}

RAZORPAY_OPTIONS = {
  "key": "",
  "amount": '',
  "name": "",
  "order_id": "",
  "description": "",
  "image": "",
  "prefill": {
    "name": '',
    "email": '',
    "contact": '',
    "method": ""
  },
  "modal": {},
  "theme": {
    "color": ""
  }
};

public proceed(resp) {
  if (localStorage.getItem("user")) {
    this.isProcessing = true
    console.log(resp)
      if (resp.success == true) {
        console.log("kkkkkkkkkkkkk")
        this.paymentDetails = resp.data
        if (this.paymentDetails.payment_status != "success") {
          console.log(this.paymentDetails)
          this.RAZORPAY_OPTIONS.amount = this.paymentDetails.razor_data.amount;
          this.RAZORPAY_OPTIONS.description = this.paymentDetails.razor_data.description;
          this.RAZORPAY_OPTIONS.key = this.paymentDetails.razor_data.key;
          this.RAZORPAY_OPTIONS.order_id = this.paymentDetails.razor_data.order_id;
          this.RAZORPAY_OPTIONS.name = this.paymentDetails.razor_data.name;
          this.RAZORPAY_OPTIONS.prefill.name = this.paymentDetails.razor_data.prefill.name;
          this.RAZORPAY_OPTIONS.prefill.email = this.paymentDetails.razor_data.prefill.email;
          this.RAZORPAY_OPTIONS.prefill.contact = this.paymentDetails.razor_data.prefill.contact;
          this.RAZORPAY_OPTIONS.theme.color = this.paymentDetails.razor_data.theme.color;
          this.RAZORPAY_OPTIONS.image = this.paymentDetails.razor_data.image;
          // binding this object to both success and dismiss handler
          this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
            let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
            razorpay.open();
        }
      } else {
        this.isProcessing = false
        this.toastr.warning(resp.message)
      } 
  }
}

public razorPaySuccessHandler(response) {
  this.razorpayResponse = 'Razorpay Response';
  let data = {
    order_id:this.paymentDetails._id,
    payment_id: this.paymentDetails._id,
    razor_payment_id:response.razorpay_payment_id,
  }
  this.otherservice.verifyPayment(data).subscribe(res => {
    if (res.success == true) {
      this.zone.run(()=>{
        this.is_place=false;
        this.cart.clearCart();
       this.cart.total_qty=0;
       this.spinner.hide();
       this.events.publishSomeData('order_updated');
       const dialogRef = this.dialog.open(OrderCompletedComponent ,{
        panelClass:"modal_sm",
        data:{
          id:res['data']._id
        }
      })
  
      dialogRef.afterClosed().subscribe(result=> {
      //  this.router.navigate(['profile'])
      });
        this.common.showSuccess("Your order successfully")
      })
    }
     else {
      this.isProcessing = false
      console.log("working");
      this.router.navigate(['checkout']);
      this.toastr.warning(res.message)
    }
  }, err => {
    this.isProcessing = false
    this.toastr.warning(err)
  });
  this.isProcessing = false
  // this.showModal = true;
  this.cd.detectChanges()
}

getDate(){
  let today:any = new Date();
  let dd = today.getDate();
  let mm = today.getMonth()+1; //January is 0!
  let yyyy = today.getFullYear();
  if(dd<10){
      dd='0'+dd
  } 
  if(mm<10){
      mm='0'+mm
  } 

  today = yyyy+'-'+mm+'-'+dd;
  console.log("today---",today);
  
}

  selectedDate(date){
    this.hasScheduledDeliveryTime = true 
    this.common.SelectedDate = date
    this.common.selectedTimeArray = []
    this.common.isInside = false
    this.timeArray.forEach(element => {
      this.common.isValidTime(element)
    });
  }

  getAddress(){
    this.otherservice.getAddress().subscribe(res=>{
      this.address=res;
      console.log(this.address)
      this.select_address=this.address.data[0];
      console.log(this.select_address)
      console.log("get address checkout",this.address);
      this.location_id=this.select_address.address_location_id
      console.log(this.location_id)
      this.getDeliveryCharge()
    })
  }
  // getTotalAmount(){
  //   this.product_price=[];
  //   this.product_prices=[];
  //   this.total_price=0;
  //   this.total=[];
  //   this.prod_qty=[];
  //   this. qty=[];
  //   if(this.cart_items!=null){
  //   this.cart_items.forEach(element => {
  //     this.product_price.push(element.data.product_price);
  //     this.prod_qty.push(element.qty);
  //   });
  //   this.product_prices=this.product_price.map(e=>JSON.parse(e));
  //   this.qty=this.prod_qty.map(d=>JSON.parse(d));
  //   for (let index = 0; index < this.product_prices.length; index++) {
      
  //   //   this.total_price=this.product_prices.reduce(function(a, b){
  //   //     return a + b;
  //   // }, 0);
  //   let total=this.product_price[index]*this.qty[index];
  //   this.total.push(total)
      
  //   }

  //   for (let index = 0; index < this.product_prices.length; index++) {
      
  //       this.total_price=this.total.reduce(function(a, b){
  //         return a + b;
  //     }, 0);
  //     }
  //   }
  // }

  selectAddress(data){
    console.log("select address",data);
    this.select_address=data;
    this.location_id=this.select_address.address_location_id
    console.log(this.location_id)
    this.getDeliveryCharge()
  }

  paymentMethod(value){
    this.is_payment=false;
    console.log(value)
    if (value=='cod') {
      this.isCod=false;
      this.payment_method='cod';
    }else if (value=='online') {
      this.isCod=false;
      this.payment_method='online';
    }else if(value=='bank_transfer'){
      console.log(value)
      this.isCod=true;
      this.payment_method='bank_transfer';
    }
    
  }
  
  datePick(data){
    console.log("date",data);
    this.selected_datetime=data;
    this.otherservice.selected_datetime=data;
    this.isDate=true;
  }

  delieryArea(data){
    this.delivery_place=data;
    console.log(this.delivery_place)
    this.d_place=false;
    
  }

  confirm(){
    var product_array = [];
    const fd=new FormData();
    // fd.append('image',this.payment_slip);
    this.cart_items.forEach(element => {
    var prod = {}
    prod['product_id'] = element.id;
    prod['product_qty'] = element.qty;
    product_array.push(prod);
    });
    let data = {
      'product_details': product_array,
      'billing_address': this.select_address,
      'shipping_address': this.select_address,
      'payment_mode': this.payment_method,
      'payable_amount':this.total_price,
      // 'payment_slip':fd,
      'delivery_location_type':this.delivery_place
    }
    if(this.delivery_charge){
      data['delivery_charge']=this.delivery_charge;
    }
    if(this.calculated_Total){
      data['payable_amount']=this.calculated_Total
    }else{
      data['payable_amount']=this.total_price
    }
    if(this.calculated_Total){
      data['order_amount']=this.calculated_Total
    }else{
      data['order_amount']=this.total_price
    }

    if (this.payment_method==''|| this.payment_method==null) {
      this.toastr.warning("select payment method");
    }else if (this.delivery_place==''||this.delivery_place==null) {
      this.toastr.warning("select where to deliver");
    }else{
      
      this.otherservice.placeOrder(data).subscribe(res=>{
        let response:any=res;
        if (response.success) {
          localStorage.removeItem('cart');
          this.toastr.success("successfully placed your order");
          const dialogRef = this.dialog.open(OrderCompletedComponent ,{
          })
      
          dialogRef.afterClosed().subscribe(result=> {
          //  this.router.navigate(['profile'])
          });
          // this.router.navigate(['/order-completed']);
        }else if (!response.success) {
          this.toastr.error("Failed to place order!!! try again...")
        }
      })
      
    }
  }


  async addDateTime(value){
    this.events.publishSomeData("commonsettings")
    const dialogRef = this.dialog.open(DateTimePickerComponent, {
      height: '100%',
      width: '20%',
      panelClass: 'logi_modal',
      data:{
      id:value
      }
    })

    dialogRef.afterClosed().subscribe(result=> {
      // if(result.data){
        console.log(result)
        this.common.selectedTime = result.time
        this.hasScheduledDeliveryDate = true
        this.hasScheduledDeliveryTime = true
        this.scheduled_time = (moment(this.common.SelectedDate).format('YYYY-MM-DD') + 'T' +  moment(this.common.selectedTime).format('HH:mm:ss')+'+0530')
        // if(this.scheduled_time != null) this.isShowMessage = false
        this.is_date=false;
        console.log(this.scheduled_time)
      // }
      
    });
  }

  addAddress(){
    const dialogRef = this.dialog.open(AddAddressComponent, {
      panelClass:"logi_modal"
    })

    dialogRef.afterClosed().subscribe(result=> {
      this.getAddress();
      this.d_address=false;
    })
      
  }

  fileChange(event: any): void {
    this.invoiceImage = event.target.files[0]
    this.imageChangedEvent = event;
    this.imageSize = Math.round(this.imageChangedEvent.target.files[0].size / 1024)

    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
   return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
    this.imgURL = reader.result;
    this.invoiceImage = this.imgURL
    console.log(this.invoiceImage)
    // this.imageChangedEvent = event;
    // this.imageSize = Math.round(this.imageChangedEvent.target.files[0].size / 1024)
  }
}


getTotalAmount(){
  this.user_details=JSON.parse(localStorage.getItem("user_details"))
  this.product_price=[];
  this.product_prices=[];
  this.total_price=0;
  this.total=[];
  this.prod_qty=[];
  this. qty=[];
  this.user_details=JSON.parse(localStorage.getItem('user_details'));
  if(this.cart_items!=null){
    this.cart_items.forEach(element => {
      if(element.bulk_prices !=null){
      element.bulk_prices.forEach(element1 => {
        // console.log(this.user_details.data.customer_group_id)
        // if(this.user_details.data.customer_group_id==element1.customer_group_id){
        if(element.qty >= element1.min_quantity){
          element.product_price=element1.product_price
          console.log(element.product_price)
        }
      //  if(element.qty < element1.min_quantity){
      //     // console.log(element.product_price)
      //     element.product_price=element.product_og_price
      //   } 
      // }
      console.log(this.cart_items)
      });
    }
    });
  this.cart_items.forEach(element => {
    this.product_price.push(element.product_price);
    this.prod_qty.push(element.qty);
  });
  this.product_prices=this.product_price.map(e=>JSON.parse(e));
  this.qty=this.prod_qty.map(d=>JSON.parse(d));
  for (let index = 0; index < this.product_prices.length; index++) {
    
  //   this.total_price=this.product_prices.reduce(function(a, b){
  //     return a + b;
  // }, 0);
  let total=this.product_price[index]*this.qty[index];
  this.total.push(total)
    
  }

  for (let index = 0; index < this.product_prices.length; index++) {
    
      this.total_price=this.total.reduce(function(a, b){
        return a + b;
    }, 0);
    console.log(this.total_price)
    }
  }
}


  placeOrder(){
    // this.spinner.show();
    console.log(this.scheduled_time)
    console.log(this.cart_items)
    var product_array = []
    this.cart_items.forEach(element => {
      var prod = {}
      prod['product_id'] = element._id;
      prod['product_qty'] = element.qty;
      product_array.push(prod);
      });
         
      let data = {
        'product_details': product_array,
        'billing_address': this.select_address,
        'shipping_address': this.select_address,
        'payment_mode': this.payment_method, 
        'delivery_location_type':this.delivery_place,
        'scheduled_date_time':this.scheduled_time
      }

      if(this.invoiceImage !=null){
        data['payment_slip']=this.invoiceImage;
      }
      if(this.delivery_charge){
        data['delivery_charge']=this.delivery_charge;
      }
      if(this.calculated_Total){
        data['payable_amount']=this.calculated_Total
      }else{
        data['payable_amount']=this.total_price
      }
      if(this.calculated_Total){
        data['order_amount']=this.calculated_Total
      }else{
        data['order_amount']=this.total_price
      }
  if(this.select_address !=null){
   if(this.common.SelectedDate !=null){
     if(this.delivery_place !=null){
    if(this.payment_method !=null){
    if(this.payment_method =='bank_transfer'){
     if(this.invoiceImage !=null){
       this.is_place=true;
      this.otherservice.placeOrder(data).subscribe(res=>{
        let response:any=res;
        if (response.success) {
          this.is_place=false;
          localStorage.removeItem('cart');
          this.cart.total_qty=0;
          this.proceedOrderPlacement(res)
          this.common.showSuccess("successfully placed your order");
          this.spinner.hide();
          this.events.publishSomeData('order_updated');
          const dialogRef = this.dialog.open(OrderCompletedComponent ,{
            panelClass:"modal_sm",
            data:{
            id:res['data']._id
            }
          })
      
          dialogRef.afterClosed().subscribe(result=> {
          //  this.router.navigate(['profile'])
          });
        }else if (!response.success) {
          this.is_place=false;
          this.spinner.hide();
          this.toastr.warning("Failed to place order!!! try again...")
        }
      }
      )
     }else{
       this.toastr.warning("please upload invoice")
     }
    }if(this.payment_method =='online'){
      this.is_place=true;
      this.otherservice.placeOrder(data).subscribe(res=>{
        this.spinner.show();
        let response:any=res;
        if (response.success) {
          this.proceedOrderPlacement(res)
        }else if (!response.success) {
          this.is_place=false;
          this.spinner.hide();
          this.toastr.warning("Failed to place order!!! try again...")
        }
      })
    }if(this.payment_method =='cod'){
      this.is_place=true;
      this.otherservice.placeOrder(data).subscribe(res=>{
        let response:any=res;
        if (response.success) {
          this.is_place=false;
          localStorage.removeItem('cart');
          this.cart.total_qty=0;
          this.proceedOrderPlacement(res)
          this.common.showSuccess("successfully placed your order");
          this.spinner.hide();
          this.events.publishSomeData('order_updated');
          console.log(res['data']._id)
          const dialogRef = this.dialog.open(OrderCompletedComponent ,{
            panelClass:"modal_sm",
            data:{
              id:res['data']._id
            }
         
          })
          dialogRef.afterClosed().subscribe(result=> {
          //  this.router.navigate(['profile'])
          });
        }else if (!response.success) {
          this.is_place
          this.spinner.hide();
          this.toastr.warning("Failed to place order!!! try again...")
        }
      }
      )
    }
    }else{
      this.toastr.warning("please choose payment method")
     this.is_payment=true
    }
  }else{
    this.toastr.warning("please choose delivery place")
    this.d_place=true;
  }
   }else{
     this.toastr.warning("please add delivery date")
     this.is_date=true;
   }
  }else{
    this.toastr.warning("Please choose address")
    this.d_address=true;
  }
  }


  proceedOrderPlacement(data) {
    if (data.success) {
      if (data.data && data.data.payment_mode == "online") {
        console.log("22222222222222222222")
        // this.common.vendorSelectedDate = null
        this.proceed(data)
      } 
    }

}

getDeliveryCharge(){
  this.otherservice.getDeliveryCharge(this.location_id).subscribe(resp=>{
    if(resp.success==true){
      if(resp.data.min_order_amount_for_free > this.total_price)
      this.delivery_charge=resp.data.delivery_charge;
       this.calculated_Total=this.total_price+this.delivery_charge;
    }
  })
}



deleteAddress(id){
  this.otherservice.deleteAddress(id).subscribe(res=>{
    let response:any=res;
    console.log("pro res",response);
    
    if (response.success == 'true') {
      this.getAddress();
    }
    
  })
}


continue(){
  if(this.cart_items!=null){
  console.log(this.select_address.length)
    if(this.select_address.length > 0 || this.select_address !=[]){
      console.log('post data',this.invoiceImage);
      console.log('payment slip',this.invoiceImage);
    //   for (var pair of this.postData.entries()) {
    //     console.log('post data',pair[0]+ ', ' + pair[1]); 
    // }
      var product_array = [];
      const fd=new FormData();
      fd.append('image',this.invoiceImage);
      this.cart_items.forEach(element => {
      var prod = {}
      prod['product_id'] = element._id;
      prod['product_qty'] = element.qty;
      product_array.push(prod);
      });
      let data = {
        'product_details': product_array,
        'billing_address': this.select_address,
        'shipping_address': this.select_address,
        'payment_mode': this.payment_method,
        'delivery_location_type':'',
        'scheduled_date_time':''
      }
      if(this.delivery_charge){
        data['delivery_charge']=this.delivery_charge
      }
      if(this.calculated_Total){
        data['payable_amount']=this.calculated_Total
      }else{
        data['payable_amount']=this.total_price
      }
      if(this.calculated_Total){
        data['order_amount']=this.calculated_Total
      }else{
        data['order_amount']=this.total_price
      }
  
      if (this.payment_method==''|| this.payment_method==null) {
        this.toastr.warning("select payment method");
      }
      // else if (this.delivery_place==''||this.delivery_place==null) {
      //   this.commonservice.presentToast("select where to deliver");
      // }
      else{
        if(this.payment_method =='bank_transfer'){
          this.is_place=true;
          console.log(this.invoiceImage)
          if(this.invoiceImage !=null){
            data[ 'payment_slip']=this.invoiceImage,
            this.otherservice.placeOrder(data).subscribe(res=>{
              let response:any=res;
              if (response.success) {
                this.events.publishSomeData('order_updated');
                localStorage.removeItem('cart');
                this.is_place=false;
                this.cart.total_qty=0;
                this.proceedOrderPlacement(res)
                this.toastr.warning("successfully placed your order");
                const dialogRef = this.dialog.open(OrderCompletedComponent ,{
                  panelClass:"modal_sm",
                  data:{
                  id:res['data']._id
                  }
                })
            
                dialogRef.afterClosed().subscribe(result=> {
                //  this.router.navigate(['profile'])
                });
              }else if (!response.success) {
                this.toastr.warning("Failed to place order!!! try again...")
              }
            }
            )
          }else{
            console.log("lllllll")
            this.is_place=false;
            this.toastr.warning("Please upload your payment slip")
          }
      
      }
 
      if(this.payment_method =='cod'){
        this.otherservice.placeOrder(data).subscribe(res=>{
          let response:any=res;
          if (response.success) {
            this.events.publishSomeData('order_updated');
            localStorage.removeItem('cart');
            this.otherservice.total_qty=0;
            this.proceedOrderPlacement(res)
            this.toastr.warning("successfully placed your order");
            const dialogRef = this.dialog.open(OrderCompletedComponent ,{
              panelClass:"modal_sm",
              data:{
              id:res['data']._id
              }
            })
        
            dialogRef.afterClosed().subscribe(result=> {
            //  this.router.navigate(['profile'])
            });
          }else if (!response.success) {
            this.toastr.warning("Failed to place order!!! try again...")
          }
        })
      }
    if(this.payment_method =='online'){
      this.is_place=true;
      this.otherservice.placeOrder(data).subscribe(res=>{
        let response:any=res;
        if (response.success) {
          // localStorage.removeItem('cart');
          // this.otherservice.total_qty=0;
          this.proceedOrderPlacement(res)
          // this.commonservice.presentToast("successfully placed your order");
          // this.router.navigate(['/order-completed']);
        }else if (!response.success) {
          this.toastr.warning("Failed to place order!!! try again...")
        }
      })
    }
        
      }
    }else{
      this.toastr.warning("You have no address assigned please contact kriztle team")
    }
  
    
    
   
      
  }
}

}
