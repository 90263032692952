import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { CommonProvider } from './common';
import { Subject } from "rxjs";
import { CartState, Product } from "./cartState";
import { EventPublishService } from './event-publish';
import { UserService } from './user.service';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})

export class CartService {

cart_item:any=[];
CART = this.general.CART;
  private cartSubject = new Subject<CartState>();
  Products:any=[]
  cartProd:any = []
  CartState = this.cartSubject.asObservable();
  updtQty:number;
  customer_group_name:any;
  total_qty:any;
  total_amount:any;
  user_details:any=[];
constructor(public http:HttpClient, public api: ApiService, public router:Router,public general:CommonProvider,public events:EventPublishService,
  public user:UserService,public toaster:ToastrService) {
  events.getObservable().subscribe((data) => {
    if(data == 'cart:updated')  {
        this.refreshCart();
        this.getTotalQuantity();
    }
    if(data == 'order_updated'){
      console.log("llllllllll")
      this.Products=null;
    }


    // if(data == 'login'){
    //   this.updateCart();
    // }
});

}

// addToCart(data){
//     this.cart_item.push(data);
//     console.log('cart service cart_item---',this.cart_item);
//     localStorage.setItem("cart",JSON.stringify(this.cart_item));
// }

loginclearcart(user){
  console.log(user)
  console.log(this.Products)
  if(this.Products !=null){
    // if(user && user.customer_group_id){
      // console.log(user.data.customer_group.customer_group_name)
  if(user.data.customer_group && user.data.customer_group.customer_group_name){
    if(user.data.customer_group.customer_group_name !='All'){
    this.toaster.warning("Your cart is cleard please continue")
    this.clearCart();
  
  }
}
// }
  }
}


  removeProduct(id){
    console.log(id)
    this.Products = this.Products.filter((_item) => _item['_id'] !== id)
        this.cartSubject.next(<CartState>{ loaded: false, products: this.Products });
        console.log(this.Products)
        if(this.Products.length > 0){
          this.events.publishSomeData(this.general.CART_UPDATED);
        }
        if(this.Products.length==0){
          this.clearCart();
        }
      
  }

  // updateCart(){
  //   console.log("hhhhhhhhhh")
  //   this.Products.forEach(element => {
  //     if(element.bulk_prices !=null){
  //       element.bulk_prices.forEach(element1 => {
  //         if(this.user.user_details.customer_group_id==element1.customer_group_id){
  //           if(element.qty >= element1.min_quantity){
  //             this.Products.find((_item) => _item._id ==element._id).product_price = element1.product_price
  //           }
  //           if(element.qty < element1.min_quantity){
  //             this.Products.find((_item) => _item._id ==element._id).product_price = element.product_og_price
  //           }
  //         } 
  //       });
     
  //   }
  //   });
  //   this.refreshCart()
  // }

  updateProduct(qunt,prod){
    // console.log(this.Products)
    //     if(this.Products !=null){
    //       this.Products.forEach(element => {
    //         if(element._id == prod._id){
      this.loadCart();
      // if(prod.bulk_prices !=null){
      //   prod.bulk_prices.forEach(element1 => {
      //     if(this.user.user_details && this.user.user_details.customer_group_id !=null ){
      //     if(this.user.user_details.customer_group_id==element1.customer_group_id){
      //     if(prod.qty >= element1.min_quantity){
      //       this.Products.find((_item) => _item._id ==prod._id).product_price = element1.product_price
      //     }
      //     if(prod.qty < element1.min_quantity){
      //       this.Products.find((_item) => _item._id ==prod._id).product_price = prod.product_og_price
      //     }
      //   }
      // }
      //   });
      // }
              var qty = this.Products.find((_item) => _item._id ==prod._id).qty += qunt
              console.log(qty)
              if (qty < 5) this.removeProduct(prod._id)
              this.events.publishSomeData(this.general.CART_UPDATED);
        //     }else{
        //       this.addProduct(prod)
        //     }
        //   });
        // }else{
        //   this.addProduct(prod)
        // }
  }

  getCart() {
    this.loadCart();
    return this.Products;
}

loadCart() {
      this.Products = JSON.parse(localStorage.getItem(this.CART));
      console.log(this.Products)
}
refreshCart() {
  console.log(this.Products)
   localStorage.setItem(this.CART,JSON.stringify(this.Products)) 
   this.loadCart()
}

clearCart() {
  this.Products = null
  this.events.publishSomeData(this.general.CART_UPDATED);
}


addProduct(_product: any,count) {
  console.log("/////////////////////")
  console.log(this.Products)
  console.log(_product._id)
  let alreadyExists = false;
  if(this.Products !=null){
  this.Products.map(item => {
      console.log(item._id)
      if (item._id == _product._id) {
          alreadyExists = true;
      }
  })
}else{
  this.Products=[];
}
  if (alreadyExists) return;
  console.log(_product)
  if(!_product.qty){
  _product.qty =count;
  }
    // if(_product.bulk_prices !=null){
    //   _product.bulk_prices.forEach(element1 => {
    //     if(_product.qty >= element1.min_quantity){
    //       _product.product_price=element1.product_price
    //     }
    //     if(_product.qty < element1.min_quantity){
    //       _product.product_price=_product.product_og_price
    //     }
    //   });
    // }
    console.log(_product.change_price)
  this.Products.push(_product)
  console.log(this.Products)
  this.cartSubject.next(<CartState>{ loaded: true, products: this.Products });
  // this.general.presentToast('Added '+_product.name+' to cart')
  this.events.publishSomeData(this.general.CART_UPDATED);
}

getTotalQuantity(){
  this.total_qty=0
  this.loadCart();
  if(this.Products !=null){
    this.Products.forEach(element => {
      console.log(element.qty)
    this.total_qty+=element.qty;
    console.log(this.total_qty);
  });
  }
}

}