<section class="modal_section_div order_sss">
	<button mat-dialog-close class="close_md_date">
		<img (click)="navigate()" src="assets/images/close.svg" class="rem_product pointer">
	</button>
	<div class="container make_it_center">
		<div class="row">
			<div class="col-md-12">
				<div class="success-tick-bg">
					<img style="filter: brightness(0);" src="assets/images/tick-big.png" class="img-fluid">
				</div>
				<p class="complated-text">Order Completed </p>
				<div class="mt20">
					<button type="button" class="next_btn_cart" (click)="navigate()" >Track Order
					</button>
				</div>
			</div>
		</div>
	</div>
</section>
 