import { Component } from '@angular/core';
import * as $ from 'jquery';
import { CommonProvider } from './providers/common';
import { OtherService } from './providers/other.service';
import { UserService } from './providers/user.service';
import { EventPublishService } from './providers/event-publish';
import { CartService } from './providers/cart.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'kriztle';

  constructor(public commonprovider:CommonProvider,public other:OtherService,public user:UserService,public events:EventPublishService,public cart:CartService){ 
    events.getObservable().subscribe((data) => {
      if(data == 'commonsettings')  {
        this.commonSettings();
      }
  });
  }

  ngOnInit() {
    this.commonSettings();
    this.user.loadUser();
    this.cart.getTotalQuantity()
    $(document).ready(function () {
      $(window).on('scroll', function () {
        if (Math.round($(window).scrollTop()) > 100) {
          $('.navbar').addClass('scrolled');
        } else {
          $('.navbar').removeClass('scrolled');
        }
      });
    }); 
    
  }

  commonSettings(){
    console.log("nnnnnnnnnnnn")
    this.other.commonSettings().subscribe(res =>{
      let result:any=res;
      if(result.success == true){
        console.log("common settings working",result);
        
        this.commonprovider.payment_gateway_enabled = result.data.payment_gateway_enabled
        this.commonprovider.cod_enabled = result.data.cod_enabled
        this.commonprovider.instant_delivery_available_time = result.data.instant_delivery_available_time
        this.commonprovider.schedule_delivery_available_time = result.data.schedule_delivery_available_time
        this.commonprovider.max_scheduled_days = result.data.maximum_schedule_days,
        console.log(this.commonprovider.max_scheduled_days)
      }
    })
   }
  
}