import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { OtherService } from '../providers/other.service';
import { ProductsService } from '../providers/products.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  bulkList:any=[]
  scehemePlan:any;
  user_details:any;
  customer_group_name:any;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any, public productservice:ProductsService,public other:OtherService) { 

  }

  ngOnInit() {
    this.user_details=JSON.parse(localStorage.getItem('user_details'));
    console.log(this.user_details)
    
    if (this.user_details !=null) {
      if(this.user_details.data.customer_group !=null){
     this.customer_group_name=this.user_details.data.customer_group.customer_group_name;
     console.log(this.customer_group_name)
      }
      // console.log(user_details.customer_group)
    }
    this.getBulkPrice();
    this.getSchemeList();
  }

  getBulkPrice(){
    this.bulkList=[];
    console.log("bulk",this.data);
      let qty:any=[];
      let result=this.data.bulk_price;
      result.sort(function( a, b ) { return  a.min_quantity -b .min_quantity })
   
      result.forEach(element => {
        qty.push(element.min_quantity);
      });
    qty=qty.sort((a,b) => a < b)
      console.log(qty)
      for (let i = 0; i < result.length; i++) {
        const elem = result[i];
        console.log(i)
        let bulk={
          "product_price":elem.product_price,
          "min_qty":elem.min_quantity,
          "max_qty":qty[i+1]-1
        } 
        this.bulkList.push(bulk);
        console.log("bulk list",this.bulkList);
        
      }
      if (this.bulkList!=undefined && this.bulkList.length!=0) {
        this.bulkList[this.bulkList.length-1].max_qty='nan';
      }
      console.log("bulk list 2",this.bulkList);
  }


  getSchemeList(){
    this.other.getSchemes().subscribe(resp=>{
      if(resp['success']){
        console.log(resp)
        this.scehemePlan=resp['data'].plan;
        console.log(this.scehemePlan)
      }
    })
    }
}
