  import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../providers/auth.service';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../views/login/login.component';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(private authservice:AuthService, private router:Router,public dailog:MatDialog){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean{
    if (this.authservice.loggedIn()) {
      return true;
    } else {
      // this.router.navigate(['/login']);
      console.log(state.url)
      const dialogRef = this.dailog.open(LoginComponent, {
        panelClass:"modal_sm",
        data:{
         url:state.url
        }
      })
  
      dialogRef.afterClosed();
      return false;
    }
  }
}