<!-- header -->
<nav class="navbar navbar-default">
    <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->

        <div class="navbar-header">
            <button (click)="openNav()" [ngClass]="status ? 'openNav' : 'closedNav'" type="button"
                class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-2">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" [routerLink]="['/home']">
                <img src="assets/images/Kriztle-Logo-white.svg" class="img-responsive logowhite" alt="Kriztle" />
                <img src="assets/images/Kriztle-Logo.svg" class="img-responsive logoOg" alt="Kriztle" />
            </a>
        </div>

        <a [routerLink]="['/cart']" class="top_cart_icon"> <img src="assets/images/shopping-cart.png">
            {{cart.total_qty}}</a>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="navbar-collapse-2" *ngIf="status"
            [ngClass]="status ? 'openNav' : 'closedNav'">
            <ul class="nav navbar-nav navbar-right">
                <li><a (click)="closeNav()" [routerLink]="['/']" class="fade-in">Home</a></li>
                <li><a (click)="closeNav()" [routerLink]="['/about']" class="fade-in">About</a></li>
                <li><a (click)="closeNav()" [routerLink]="['/products']" class="fade-in">Products</a></li>
                <li><a (click)="closeNav()" [routerLink]="['/contact']" class="fade-in">Contact</a></li>
                <li (click)="closeNav()"><a (click)="gotoLogin()" class="fade-in" *ngIf="!user.hasLoggedIn">Login</a>
                </li>
                <ng-container *ngIf="!mobile_nav">
                    <li *ngIf='user.hasLoggedIn' (click)="accountView()" class="drop_down_div"><a class="fade-in"> <img
                                class="top_imag" src="assets/images/avatar.png"></a>
                        <ul *ngIf='user.hasLoggedIn && isAccount'>
                            <li (click)="closeNav()"><a [routerLink]="['/profile']" class="inverted-bar fade-in">My
                                    Profile</a></li>
                            <li (click)="closeNav()"><a [routerLink]="['/my-orders']" class="inverted-bar fade-in">My
                                    Orders</a></li>
                            <li (click)="closeNav()"
                                *ngIf="customer_group =='dealer' || customer_group =='distributors'"><a
                                    [routerLink]="['/scheme']" class="inverted-bar fade-in">My Schemes</a></li>
                            <li><a (click)="logout()" class="inverted-bar fade-in">Logout</a></li>
                        </ul>
                    </li>
                </ng-container>


                <ng-container *ngIf="mobile_nav">
                    <li *ngIf='user.hasLoggedIn' (click)="accountView()" class="drop_down_div"><a class="fade-in">
                            Profile </a>
                        <ul *ngIf='user.hasLoggedIn && isAccount'>
                            <li (click)="closeNav()"><a [routerLink]="['/profile']" class="inverted-bar fade-in">My
                                    Profile</a></li>
                            <li (click)="closeNav()"><a [routerLink]="['/my-orders']" class="inverted-bar fade-in">My
                                    Orders</a></li>
                            <li (click)="closeNav()"
                                *ngIf="customer_group =='dealer' || customer_group =='distributors'"><a
                                    [routerLink]="['/scheme']" class="inverted-bar fade-in">My Schemes</a></li>
                            <li><a (click)="logout()" class="inverted-bar fade-in">Logout</a></li>
                        </ul>
                    </li>
                </ng-container>

                <li>
                    <a (click)="closeNav()" [routerLink]="['/cart']" class="fade-in"> <img class="top_imag"
                            src="assets/images/shopping-cart.png"> {{cart.total_qty}}</a>
                </li>
                <li>
                    <div class="social-icons">
                        <a href="#">
                            <img src="assets/images/insta.png" class="img-responsive logowhite" alt="instagram" />
                            <img src="assets/images/instablack.png" class="img-responsive logoOg" alt="instagram" />
                        </a>
                        <a href="https://www.facebook.com/kriztle.g" target="_blank">
                            <img src="assets/images/fb.png" class="img-responsive logowhite" alt="facebook" />
                            <img src="assets/images/fbblack.png" class="img-responsive logoOg" alt="facebook" />
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- /.navbar-collapse -->
    </div>
    <!-- /.container -->
</nav>
<!--// header -->