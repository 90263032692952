import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Validator } from 'src/app/_helpers/validators';
import { UserService } from '../../providers/user.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OtherService } from '../../providers/other.service';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.css']
})
export class AddProfileComponent implements OnInit {

  profileForm:FormGroup
  customerModel:any;
  submitted:boolean=false;
  validator=new Validator
  profileData:any=[];
  isEdit:boolean=false;
  mobile_no:any;

  constructor(public formBuilder:FormBuilder,public toaster:ToastrService,public user:UserService,public router:Router,
    public dailog:MatDialog,public dialogRef: MatDialogRef<AddProfileComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
    public other:OtherService) { 
      if(data !=null){
       this.profileData=data.id
        this.isEdit=true;
       console.log(this.profileData)

      }
    }

  ngOnInit(): void {

    this.profileForm=this.formBuilder.group({
      name:['',Validators.required],
      email:[''],
      phone:['',[Validators.required,Validators.pattern(this.validator.numberPattern()), Validators.minLength(10)]],
      gst:[''],
    })
     this.customerModel=null;
    if(this.isEdit){
      this.profileForm.get('name').patchValue(this.profileData.customer_name );
      this.profileForm.get('email').patchValue(this.profileData.customer_email );
      this.profileForm.get('phone').patchValue(this.profileData.customer_mobile );
      this.profileForm.get('gst').patchValue(this.profileData.gst_number );
      this.customerModel=this.profileData.customer_type;
    }
    console.log(this.customerModel)
    this.getProfileDetails();
  }


  get acf() { return this.profileForm.controls; }

  customerType(value){
  if(value == 'p'){
    this.customerModel='personal'
  }
  if(value=='c'){
    this.customerModel='corporate'
  }
  console.log(this.customerModel)
  }
 
  addProfile(){
    this.submitted=true;
    if(this.profileForm.valid){
      if(this.customerModel !=null){
      var data={
        customer_name:this.acf.name.value,
        customer_email:this.acf.email.value,
        customer_mobile:this.acf.phone.value,
        customer_type:this.customerModel,
        gst_number:this.acf.gst.value
      }
      // if(this.customerModel=="Corporate"){
      //  data['gst_number']=this.acf.gst.value
      // }
      if(this.isEdit){
        data['customer_type']=this.profileData.customer_type;
      }
      this.user.addProfile(data).subscribe(resp=>{
        if(resp.success==true){
          this.dialogRef.close();
          console.log(resp)
          this.router.navigate(['profile'])
        }
      })
      }else{
       this.toaster.warning("Please select customer type")
      }
    }
  }

  getProfileDetails(){
    this.other.getProfile().subscribe(resp=>{
      console.log(resp)
    this.mobile_no=resp['data'].customer_mobile
    this.profileForm.get('phone').patchValue(this.mobile_no);
    })
  }
}
