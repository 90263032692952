<section id="innerbanner">
    <div class="container">
      <div class="row">
        <div class="breadcrumb">
          <h1 id="terms">TERMS AND THE PRIVACY POLICY</h1>
          <span><a [routerLink]="['/']" title="Home">Home</a></span>
        </div>
      </div>
    </div>
  </section>
<section class="profile">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="order_list p-left">
                    <h2>Shan International.</h2>
                    <p class="block font_14 medium mt20 mb15">TERMS AND THE PRIVACY POLICY</p>
                    <p class="block font_12 mb20">
                        These Terms & Conditions (“Terms”) govern use of our website/app www.kriztleglobal.com/
                        (“Website/Application/App/We”), or any products or services in connection with the Application/,
                        Website/products. Please read the Terms and the privacy policy of Shan International (“Privacy
                        Policy”) with respect to registration, use of the App/Website/Services and/or products carefully
                        before using the same. In the event of any discrepancy between the Terms and any other policies
                        with respect to the
                    </p>
                    <p class="block font_14 medium mt20 mb15" id="privacy">PRIVACY POLICY</p>
                    <p class="block font_12 mb20" >
                        By using or accessing Shan International, the User hereby agrees with the terms of this Privacy
                        Policy and the Terms and Conditions. By using the Services, you are agreeing to the terms of the
                        Privacy Policy. The Privacy Policy is subject to revisions at any time, as determined by Us,
                        without notice, and any such changes are effective immediately upon being posted on Shan
                        International; any use of the Services thereafter will be deemed to be an acceptance of these
                        changes by You.
                    </p>
                    <p class="block font_12 mb20">1 -Type of information collected <br>
                        Shan International collects user information to ensure that Services being provided by Shan
                        International can be used by Users effectively. Shan International collects information from
                        Users, including operating system, browser type, and the name of the internet service provider.
                    </p>
                    <p class="block font_12 mb20">
                        2 -Personal Information<br>
                        Shan International collects various types of information to establish and maintain your account
                        with Shan International. We collect your full name, e-mail address, telephone number,
                        residential address and/or billing address in order to provide Services. We also collect
                        information we receive from other public sources and any other information received from a third
                        party in order to provide, and allow you to use the Services. We also collect information from
                        your computer or mobile device, such as unique identification number, model, operating system
                        and version, your browser type and version, the presence of any device, the portion of Services
                        used, and time and location of access of Services.
                    </p>
                    <p class="block font_12 mb20">
                        3 -Communication<br>
                        You consent to receive communication including advertisement and review calls from Shan
                        International and also to take part in surveys in relation to your use of Services. The
                        information received is used to make further improvements to our Services and to develop better
                        features and promotions for users.
                    </p>
                    <p class="block font_12 mb20">
                        4 -Purpose of collection and use of information<br>
                        We collect, store and disclose User information relating in order to provide the Services either
                        directly or through third party service providers and to fulfil your requests regarding Services
                        including but not limited to confirming reservations, communicating transaction status, sending
                        booking confirmations and updates and allowing customer service to contact You. We may also use
                        information provided by you to: (i) enforce our Terms of Use Agreement; (ii) monitor user
                        activity and effectively manage User accounts and our website; (iii) assist you with technical
                        difficulties; (iv) maintain User accounts; (v) collect fees; and (vi) minimizing fraud and abuse
                        of Services. Certain information may be collected each time Users access or use the website
                        which is saved in server logs, which includes IP address, operating system and browser on/from
                        which Users are accessing the Website. We may also collect User information to personalize your
                        experience on the website and sending you information and notifications regarding Services. We
                        also collect User information to provide you with offers and deals from third parties.<br>
                        Shan International will retain your information as long as it is reasonably necessary for the
                        purposes listed in this policy. In some circumstances, it might be retained for longer periods
                        of time in accordance with any legal, regulatory, tax or accounting requirements. When your
                        personal data is no longer required it shall be securely deleted or stored in a way which means
                        it will no longer be used by Shan International.
                    </p>
                    <p class="block font_12 mb20">
                        5 -Data Collection Devices<br>
                        We may use data collection devices known to collect and store information of users accessing
                        Shan International, including cookies and trackers. Cookies are used to identify information
                        specific to you including your browsing history and search preference. Cookies allow us to serve
                        you better and more efficiently. You also consent to sharing your geo-location data in order to
                        publish information/ advertisements offered by Users closers to your location and vice-versa.
                    </p>
                    <p class="block font_12 mb20">
                        6 -Disclosure to Third Parties<br>
                        When we share User information with third parties, we hold all such parties to the same or
                        higher standards as we do ourselves regarding the privacy of your information. Shan
                        International publishes and discloses User information to provide effective access to Service,
                        to comply with legal obligation and enforce the Terms of Use Agreement. We may use third party
                        individuals/entities to perform certain parts of the Services and as a result may share your
                        User information. You hereby authorize Shan International to collect, use, store, analyse,
                        reproduce or publish (either on its own or through third party service provider(s) the
                        information in relation to your use of Shan International for data analysis and for improving
                        your experience in Shan International. We may also share some User information with third party
                        advertisers who are interested in advertising their product to you. You can opt-out of this
                        service by contacting us through the contact details given below or by clicking the unsubscribe
                        link. We may disclose information about our users to law enforcement officers or others, in the
                        good faith belief that such disclosure is reasonably necessary to enforce the Terms of Use
                        Agreement and/or Privacy Policy; respond to claims that any content violates the rights of
                        third-parties; or protect the rights, property, or personal safety of our users, the general
                        public or ourselves. You agree and acknowledge that we may not inform you prior to or after
                        disclosures made according to this section. If substantially all of our assets are sold or
                        merged into another company, the acquiring company shall be given access to your personal or
                        other information without your consent. If we sell only a part of our business, the acquiring
                        entity shall have access to your User information without your consent.
                    </p>
                    <p class="block font_12 mb20">
                        7 -Security Practices and Procedures<br>
                        We strive to ensure confidentiality and security of User information by using commercially and
                        legally acceptable means. We also have a comprehensive documented information security program
                        and information security policies in place. Additionally, we use firewalls and data encryption.
                        However, as with most electronic transactions, no method is 100% safe. Therefore, you
                        acknowledge and agree that we assume no liability regarding the theft, loss, alteration or
                        misuse of personal or other information or content, including, without limitation, such
                        information that has been provided to third parties or other users, or with regards to the
                        failure of a third party to abide by the agreement between us and such third party. You also
                        play an important role in keeping your personal information secure. You should not share your
                        user name, password, or other security information for your Shan International account with
                        anyone. If we receive instructions using your username and password, we will consider that you
                        have authorized the instructions. In the unlikely event that we come to learn that either our
                        system or your account specifically has suffered a security breach, we may attempt to notify you
                        electronically so that you can take appropriate protective steps, by e-mail, text message, phone
                        call, a notice on our website, or any other way, depending upon the requirements of the
                        applicable law.
                    </p>
                    <p class="block font_12 mb20">
                        9 -Change in Privacy Policy<br>
                        We reserve the right to update, modify and amend any of the terms of this Privacy Policy, at any
                        time without prior intimation to you. We will post these changes on Shan International. These
                        changes will become effective immediately on posting. We shall not be liable for any failure or
                        negligence on your part to review the updated Privacy Policy before accessing or using Shan
                        International. Your continued use of Shan International, following changes to the Privacy
                        Policy, will constitute your acceptance of those changes.
                    </p>
                    <p class="block font_12 mb20">
                        If you choose to use the embedded link function, you agree not to embed a link on or within any
                        illegal site, including but not limited to any site that is indecent, obscene or pornographic or
                        that is determined to be otherwise objectionable in our sole and absolute discretion, and we
                        reserve the right to disable embedded links at any time without notice to any Users.
                    </p>
                    <p class="block font_12 mb20" id="refund-policy">
                        Any violation of these above terms and conditions will leave Users liable to having their
                        Services terminated. The above statements are taken very seriously by Shan International and
                        violation of these statements will not be tolerated.
                    </p>
                    <p class="block font_14 medium mb15">
                        PRICING:
                    </p>
                    <p class="block font_12 mb20">
                        All prices are subject to change without notice. <br>
                        Gift coupons/price discounts/ promotional offers etc are at the sole discretion of Shan
                        International.
                    </p>

                    <p class="block font_14 medium mb15" id="shipping-policy">
                        REFUNDS: 
                    </p>
                    <p class="block font_12 mb20">
                        Products once purchased will NOT be refunded, except in case the same program is purchased twice due to some server error, for which the following refund rules will apply - If the buyer informs us within 7 days, full refund would be done for only one program.
                    </p>

                    <p class="block font_14 medium mb15">
                        SHIPPING POLICY: 
                    </p>
                    <p class="block font_12 mb20">
                        We would take 48 Hours for your order to be processed for shipping and deliver to your address. 
                        Free delivery available across Kerala, India
                    </p>

                    <p class="block font_14 medium mb15">
                        FEEDBACK:
                    </p>
                    <p class="block font_12 mb20">
                        Any feedback You provide with respect to the Application shall be deemed to be non-confidential.
                        The Application shall be free to use such information on an unrestricted basis. For
                        communicating feedback, contact us at info@kriztleglobal.com
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
