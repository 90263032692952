import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EventPublishService } from './event-publish';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  isuser:boolean=false;
  api_token:any;
  user_details:any;
  user:any;
  apiKey:any;
  hasLoggedIn:boolean=false;

  constructor(public http:HttpClient, public api:ApiService,public events:EventPublishService) { }

  getOtp(data){
    let endpoint="login-generate-otp";
    let mobile={
      "customer_mobile": '91'+data.mobile
    }
    return this.http.post(this.api.url+endpoint,mobile);
  }
  
  verifyOtp(mobile,otp){
    let endpoint="login-verify-otp";
    let otp_data={
      "customer_mobile": "91"+mobile.mobile,
      "otp": otp
    }
    return this.http.post(this.api.url+endpoint,otp_data);
  }
  
  userSet(){
    localStorage.setItem("user",JSON.stringify(this.isuser));
    localStorage.setItem("api_token",JSON.stringify(this.api_token));
    localStorage.setItem("user_details",JSON.stringify(this.user_details));
  }

  loadUser() {
    if(localStorage.getItem('api_token')){
      console.log("lllllllllll")
      this.apiKey = localStorage.getItem(this.api_token);
      this.hasLoggedIn = true;
    }else{
    }
  }


sendContactData(data){
  let endpoint='contact';
  return this
  .api
  .post(endpoint,data)
  .pipe(map(resp=>resp.json()))
}

addProfile(data){
  let endpoint='profile'
 return this
 .api
 .put(endpoint,data)
 .pipe(map(resp=>resp.json()))
}
}
