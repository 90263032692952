import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EventPublishService } from 'src/app/providers/event-publish';
import { OtherService } from 'src/app/providers/other.service';
import {Pipe,PipeTransform} from '@angular/core'

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent implements OnInit {

  public selectControl = new FormControl();
  page: any = 1;
  perpage: any = 10;
  status:any="";
  orders:any;


  public selectItems = [
    'all','ordered','shipped','delivered','cancelled'
  ];
  constructor(public otherservice:OtherService, public router:Router,public events:EventPublishService) { 
    events.getObservable().subscribe(async (data) => {
      if(data == 'order_updated')  {
          this.loadOrders();
      }
    })
  }

  ngOnInit() {
    this.loadOrders();
  }

  loadOrders(){
    this.otherservice.getOrders(this.page,this.perpage,this.status).subscribe(res=>{
      console.log("get orders",res);
      let data:any=res;
      this.orders=data.data.data;
      console.log("orders--",this.orders);
      
    })
  }

  selectionChanged(event){
    if (event.target.value=='all') {
      this.status='';
    }else{
      this.status=event.target.value;
    }
    this.loadOrders();
  }

  trackOrder(id){
    this.otherservice.track_id=id;
    this.router.navigate(['/order-details',id]);
  }

}
