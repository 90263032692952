import { CartService } from './../../providers/cart.service';
import { Component, OnInit, Injectable, HostListener, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/providers/products.service';
import { ToastrService } from 'ngx-toastr';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { EventPublishService } from 'src/app/providers/event-publish';
import { OtherService } from 'src/app/providers/other.service';
import { CommonProvider } from 'src/app/providers/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Product } from '../../providers/cartState';
import { LoginComponent } from '../login/login.component';

declare var jQuery
declare var $


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  page: number = 1;
  perpage: number = 100;
  products: any;
  pet_page: number = 1;
  pet_perpage: number = 10;
  glass_page: number = 1;
  glass_perpage: number = 10;
  pet_bottle: any = [];
  glass_bottle: any = [];
  totalItems_glass: any;
  totalItems_pet: any;
  cart_item: any = JSON.parse(localStorage.getItem('cart'));
  isHome: boolean = true;
  isDealer: boolean;
  isDistributor: boolean;
  catogaries: any = [];
  cart_items: any = [];
  quantity: any;
  user_details: any = [];
  api_token: any;
  cato: string;
  cat_id: any;
  active_id: any;
  is_mobile_view: boolean = false;
  is_web: boolean = false;
  addressData = [];
  min_order_amount: any;
  customer_group_name: any;
  min_quantity:any;



  constructor(public router: Router, public productservice: ProductsService, public cartservice: CartService,
    private toastr: ToastrService, public dialog: MatDialog, public events: EventPublishService, public otherservice: OtherService,
    public common: CommonProvider, public dailog: MatDialog, public zone: NgZone) {

    this.onResize();
    events.getObservable().subscribe((data) => {
      if (data == 'cart:updated') {
        this.cart_items = JSON.parse(localStorage.getItem('cart'))
        this.priceChange();
      }
      if (data == 'login') {
        this.check();
        this.loadCatogaries();
        this.getAddress()
      }
      if (data == 'logout') {
        console.log("kkkkkkkkkkk")
        this.customer_group_name = null
        this.cart_items = null;
        this.check()
        this.loadCatogaries();
      }
      if (data == 'order_updated') {
        this.cart_items = null;
      }
    });
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<img src="assets/images/prev-arrow.png">', '<img src="assets/images/next-arrow.png">'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,

  }
  homeSlider: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<img src="assets/images/prev-arrow.png">', '<img src="assets/images/next-arrow.png">'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true,
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.check();
    this.active_id = 0;
    this.loadCatogaries();
    this.getProducts();
    this.getAddress();
    this.cart_items = JSON.parse(localStorage.getItem('cart'));
  }
  ionViewWillEnter() {

  }



  catg(cat) {
    this.cato = cat.catalog_category_name;
    cat._id = !cat._id;
  }

  check() {
    console.log("kkkkkkkkkkkkkkk")
    const user_details = JSON.parse(localStorage.getItem('user_details'));
    this.user_details = JSON.parse(localStorage.getItem('user_details'))
    console.log(this.user_details)
    if (user_details != null && user_details.data.customer_group_id != null) {
      console.log("kkkkkkkkkkkkkkk")
      this.customer_group_name = user_details.data.customer_group.customer_group_name;
      console.log(this.customer_group_name)
    } else {
      this.isHome = true;
    }

    if (this.customer_group_name != 'dealer' && this.customer_group_name != 'distributors') {
      this.min_quantity=5
      this.isHome = true;
      this.isDealer = false;
      this.isDistributor = false;
    } else if (this.customer_group_name == 'dealer') {
      this.min_quantity=5
      this.isDealer = true;
      this.isDistributor = false;
      this.isHome = false;

    } else if (this.customer_group_name == 'distributors') {
      this.min_quantity=25
      this.isDistributor = true;
      this.isDealer = false;
      this.isHome = false;
    }
    console.log(  this.isDistributor, 
      this.isDealer ,
      this.isHome )
  }

  loadCatogaries() {
    this.productservice.getCatogories().subscribe(resp => {
      this.catogaries = resp.data;
      this.catogaries.forEach(element => {
        // element.children.forEach(element1 => {

        this.productservice.getCatogeriesProducts(this.page, this.perpage, element._id).subscribe(resp => {
          element.products = resp['data']
          console.log(this.catogaries)
          if (element.products && element.products.data) {
            element.products.data.forEach(element3 => {
              element3.change_price = element3.product_price
              if(this.customer_group_name == 'dealer' || this.customer_group_name == 'distributors' ){
                element3.bulk_prices.forEach(bulk => {
                  if(bulk.min_quantity <= 1){
                    element3.change_price=bulk.product_price
                  }
                });
              }
              console.log(element3.change_price)
              if (this.cart_items != null) {
                this.cart_items.forEach(element2 => {
                  if (element2._id == element3._id) {
                    element3.qty = element2.qty
                    element3.bulk_prices.forEach(element4 => {
                      if (element3.qty >= element4.min_quantity && element3.change_price > element4.product_price) {
                        element3.change_price = element4.product_price
                      
                      }
                    });
                  }
                });
              }
            })
          }
          // })
        });
      });
      this.cato = resp.data[0].catalog_category_name;
    })
  }


  getProducts() {
    this.productservice.getAllProducts(this.page, this.perpage).subscribe(res => {
      let result: any = res;
      this.products = result.data.data;
    })
  }

  petBottle() {
    this.productservice.getFeaturedProduct(this.pet_page, this.pet_perpage).subscribe(res => {
      let response: any = res;
      let product = response.data.data;
      this.pet_bottle = [];
      product.forEach(element => {
        let prod = {
          data: element,
          "id": element._id,
          "qty": 0,
          "product_price": element.product_price
        }
        this.pet_bottle.push(prod);
      });
      this.totalItems_pet = response.data.total;
      // this.getPetQuantity();
    })
  }

  glassBottle() {
    this.productservice.getProducts(this.glass_page, this.glass_perpage).subscribe(res => {
      let response: any = res;
      let product = response.data.data;
      this.glass_bottle = [];
      product.forEach(element => {
        let prod = {
          data: element,
          "id": element._id,
          "qty": 0,
          "product_price": element.product_price
        }
        this.glass_bottle.push(prod);
      });
      this.totalItems_glass = response.data.total;
      // this.getGlassQuantity();
    })
  }


  addToCart(prod) {
    console.log(prod.change_price)
    var count = 0
    if (prod.product_availability == '1') {
      if (this.customer_group_name == 'dealer') {
        count = 5
      }
      if (this.customer_group_name == 'distributors') {
        count = 25
      }
      if (this.customer_group_name !== 'dealer' && this.customer_group_name !== 'distributors') {
        count = 5
      }

      console.log(count)
      this.cartservice.addProduct(prod, count)
    } else {
      this.toastr.warning("Sorry this item is out of stock")
    }
  }

  checkId(id) {
    var flag = false;
    if (this.cart_items != null) {
      this.cart_items.forEach(element => {
        if (!flag && element._id && element._id == id) {

          flag = true;
        }
      });

    }
    return flag;
  }
  checkClass(id) {
    var flag = "activated";
    if (this.cart_items != null) {
      this.cart_items.forEach(element => {
        if (element._id && element._id == id) {
          flag = "disabled";
        }
      });

    }
    return flag;
  }

  async priceDetails(id) {
    this.api_token = localStorage.getItem('api_token')
    if (this.api_token != null) {
      console.log(id)
      // var index= this.cart_items.findIndex(o=> o._id == id);
      let dialogRef: any;
      let result: any;
      // if (type=='home') {
      // console.log("inside home");  
      // var i = this.glass_bottle.findIndex(o => o._id == id);
      this.catogaries.forEach(element => {
        // element.children.forEach(element7=> {
        element.products.data.forEach(async element1 => {
          console.log(element1)
          console.log(element1.bulk_prices)
          if (element1._id == id) {
            if (element1.bulk_prices.length > 0) {
              dialogRef = this.dialog.open(DialogComponent, {
                data: { id: id, bulk_price: element1.bulk_prices },
                panelClass: "modal_sm",
              })
              dialogRef.afterClosed().subscribe(res => {
                result = res;
                console.log(result)
                if (result != undefined && result != '') {
                  element1.qty = result.min_qty;
                  console.log("llllllllllll")
                  // this.cart_items[index].qty=result.min_qty;
                  console.log(result.product_price)
                  element1.change_price = result.product_price;
                  console.log(element1.change_price)
                  if (this.cart_items != null) {
                    this.cart_items.forEach(element2 => {
                      if (element2._id == element1._id) {
                        this.zone.run(() => {
                          element2.qty = element1.qty
                          element2.change_price = element1.change_price
                          console.log(element2)
                          localStorage.setItem('cart', JSON.stringify(this.cart_items));
                          this.otherservice.getTotalQty();
                          this.otherservice.getTotalAmount();
                          this.cartservice.loadCart();
                          this.cartservice.getTotalQuantity()
                        })
                      } else {
                        this.addToCart(element1)
                      }
                    });
                  } else {
                    // if(this.cart.Products ==null){
                    //   this.cart.Products=[];
                    //   this.cart.Products.push(element1)
                    //   this.cart.refreshCart();
                    //   this.otherservice.getTotalAmount();
                    //   this.otherservice.getTotalQty();
                    // }
                    console.log(element1.change_price)
                    this.addToCart(element1)
                  }
                  // this.cart_items[index].product_price=result.product_price;
                }
              })
            } else {
              this.toastr.warning("Sorry no offers to this Product")
            }
          }
        });
        // });


      });

      // }
      // else if(type=='dealer') {
      //   console.log("inside dealer");
      //   if (opt=='glass') {
      //     var i = this.glass_bottle.findIndex(o => o.id == id);
      //     dialogRef=this.dialog.open(Dialog1Component)
      //     dialogRef.afterClosed().subscribe(res=>{
      //       result=res;
      //       console.log("result",res);
      //       // if (result!=undefined && result!='') {
      //       //   this.glass_bottle[i].qty=result.min_qty;
      //       //   this.cart_items[index].qty=result.min_qty;
      //       //   this.glass_bottle[i].product_price=result.product_price;
      //       //   this.cart_items[i].product_price=result.product_price;
      //       //   localStorage.setItem('cart',JSON.stringify(this.cart_items));
      //       // }
      //     })
      //   }
      // else if (opt=='pet') {
      //   var i = this.pet_bottle.findIndex(o => o.id == id);
      //   dialogRef=this.dialog.open(Dialog1Component)
      //   dialogRef.afterClosed().subscribe(res=>{
      //     result=res;
      //     console.log("result",res);
      //     // if (result!=undefined && result!='') {
      //     //   this.pet_bottle[i].qty=result.min_qty;
      //     //   this.cart_items[index].qty=result.min_qty;
      //     //   this.pet_bottle[i].product_price=result.product_price;
      //     //   this.cart_items[i].product_price=result.product_price;
      //     //   localStorage.setItem('cart',JSON.stringify(this.cart_items));
      //     // }
      //   })
      // }
      // }else if(type=='distributor') {
      //   console.log("inside distributor");
      //   if (opt=='glass') {
      //     var i = this.glass_bottle.findIndex(o => o.id == id);
      //     dialogRef=this.dialog.open(Dialog2Component)
      //     dialogRef.afterClosed().subscribe(res=>{
      //       result=res;
      //       console.log("result",res);
      //       // if (result!=undefined && result!='') {
      //       //   this.glass_bottle[i].qty=result.min_qty;
      //       //   this.cart_items[index].qty=result.min_qty;
      //       //   this.glass_bottle[i].product_price=result.product_price;
      //       //   this.cart_items[i].product_price=result.product_price;
      //       //   localStorage.setItem('cart',JSON.stringify(this.cart_items));
      //       // }
      //     })
      // }else if (opt=='pet') {
      //   var i = this.pet_bottle.findIndex(o => o.id == id);
      //   dialogRef=this.dialog.open(Dialog2Component)
      //   dialogRef.afterClosed().subscribe(res=>{
      //     result=res;
      //     console.log("result",res);
      //     // if (result!=undefined && result!='') {
      //     //   this.pet_bottle[i].qty=result.min_qty;
      //     //   this.cart_items[index].qty=result.min_qty;
      //     //   this.pet_bottle[i].product_price=result.product_price;
      //     //   this.cart_items[i].product_price=result.product_price;
      //     //   localStorage.setItem('cart',JSON.stringify(this.cart_items));
      //     // }
      //   })
      // }
      // }

    } else {
      // this.router.navigate(['/login']);
      const dialogRef = this.dailog.open(LoginComponent, {
        panelClass: "modal_sm"
      })

      dialogRef.afterClosed();
    }


  }



  increaseProduct(prod, value) {
    if (prod.product_availability == '1') {
      console.log(this.cart_items)
      if (this.cart_items != null) {
        this.cart_items.forEach(element => {
          if (element._id == prod._id) {
            if (value == 'inc') {
              this.catogaries.forEach(element => {
                // element.children.forEach(element7 => {
                element.products.data.forEach(element2 => {
                  if (element2._id == prod._id) {
                    if (this.customer_group_name == 'dealer') {
                      element2.qty += 5
                    }
                    if (this.customer_group_name == 'distributors') {
                      element2.qty += 25
                    }
                    if (this.customer_group_name !== 'dealer' && this.customer_group_name !== 'distributors') {
                      element2.qty += 1
                    }
                  }
                });
                // });
              });

              if (this.customer_group_name == 'dealer') {
                this.quantity = 5
              }
              if (this.customer_group_name == 'distributors') {
                this.quantity = 25
              }
              if (this.customer_group_name !== 'dealer' && this.customer_group_name !== 'distributors') {
                this.quantity = 1
              }

            }
            if (value == 'dec') {
              this.catogaries.forEach(element => {
                // element.children.forEach(element7 => {
                element.products.data.forEach(element2 => {
                  if (element2._id == prod._id) {
                    console.log("11111111111111111")
                    if (element2.qty != 0) {
                      if (this.customer_group_name == 'dealer') {
                        if (element2.qty != 5) {
                          element2.qty -= 5
                        }
                        if (element2.qty == 5) {
                          element2.qty = 0
                        }
                      }
                      if (this.customer_group_name == 'distributors') {
                        if (element2.qty != 25) {
                          element2.qty -= 25
                        }
                        if (element2.qty == 25) {
                          element2.qty = 0
                        }
                      }
                      if (this.customer_group_name !== 'dealer' && this.customer_group_name !== 'distributors') {
                        if (element2.qty != 5) {
                          element2.qty -= 1
                        }
                        if (element2.qty == 5) {
                          element2.qty = 0
                        }
                      }
                    }
                  }
                });
                // });

              });

              this.quantity = 0
              if (this.customer_group_name == 'dealer') {
                this.quantity -= 5
              }
              if (this.customer_group_name == 'distributors') {
                this.quantity -= 25
              }
              if (this.customer_group_name !== 'dealer' && this.customer_group_name !== 'distributors') {
                this.quantity -= 1
              }

            }
            if (prod.bulk_prices.length > 0) {
              prod.change_price = prod.product_price
              console.log("66666666666666")
              prod.bulk_prices.forEach(element1 => {
                //  if(this.user_details.customer_group_id==element1.customer_group_id){
                console.log(prod.qty, element1.min_quantity)
                if (prod.qty >= element1.min_quantity || prod.qty == element1.min_quantity && prod.change_price > element1.product_price) {
                  if (!prod.change_price) {
                    console.log("666666666666")
                    console.log(prod, element1.product_price)
                    this.updatePrice(prod, element1.product_price)
                  }
                  // else{
                  //   if(prod.qty > element1.min_quantity  && prod.change_price > element1.product_price){
                  //     this.updatePrice(prod,element1.product_price)
                  //   }
                  // }

                }
                //  if(prod.qty < element1.min_quantity){
                //    console.log("llllllllll")
                //    this.updatePrice(prod,prod.product_og_price)
                //  }
                //  }
              });
            }
          }
          if (element._id != prod._id && value == 'inc') {
            var count = 0
            if (this.customer_group_name == 'dealer') {
              count = 5
            }
            if (this.customer_group_name == 'distributors') {
              count = 25
            }
            if (this.customer_group_name !== 'dealer' && this.customer_group_name !== 'distributors') {
              count = 5
            }

            console.log(count)
            this.cartservice.addProduct(prod, count)
          }
          if (element._id == prod._id) {
            console.log(this.quantity)
            this.cartservice.updateProduct(this.quantity, prod)
          }
        });
      }
      if (this.cart_items == null && value == 'inc') {
        var count = 0
        if (this.customer_group_name == 'dealer') {
          count = 5
        }
        if (this.customer_group_name == 'distributors') {
          count = 25
        }
        if (this.customer_group_name !== 'dealer' && this.customer_group_name !== 'distributors') {
          count = 5
        }

        console.log(count)
        this.cartservice.addProduct(prod, count)
        console.log("kkkkkkkkkkkkkkkkk")
      }
    } else {
      this.toastr.warning("sorry this item is out of stock")
    }
  }


  updatePrice(data, price) {
    this.catogaries.forEach(element => {
      // element.children.forEach(element7 => {
      element.products.data.forEach(element3 => {
        if (element3._id == data._id) {
          element3.change_price = price;
        }
      });
      // });

    });
  }


  priceChange() {
    this.catogaries.forEach(element2 => {
      // element2.children.forEach(element5 => {
      element2.products.data.forEach(element => {
        if (this.cart_items != null) {
          this.cart_items.forEach(element1 => {
            if (element._id == element1._id) {
              element.qty = element1.qty
              if (element.bulk_prices != null) {
                element.bulk_prices.forEach(element3 => {
                  if (element.qty >= element3.min_quantity || element.qty == element3.min_quantity && element.change_price > element3.product_price) {
                    element.change_price = element3.product_price
                  }
                  // if(element.qty < element3.min_quantity){
                  //   if(element3.min_quantity < element3.min_quantity)

                  // }
                });
              }
            }
          });
        }
      });
      // });

    });

  }


  goto() {
    console.log("kkkkkkkkkk")
    this.router.navigate(['cart'])
  }

  getSub(cat, id, data: any = null) {
    if (data == 'all') {
      this.active_id = 0
    } else {
      this.active_id = id;
    }

    this.productservice.getCatogeriesProducts(this.page, this.perpage, id).subscribe(resp => {
      cat.products = resp['data']
      if (cat.products && cat.products.data) {
        cat.products.data.forEach(element3 => {
          element3.change_price = element3.product_price
          console.log(this.catogaries)
          if (this.cart_items != null) {
            this.cart_items.forEach(element2 => {
              if (element2._id == element3._id) {
                element3.qty = element2.qty
                element3.bulk_prices.forEach(element4 => {
                  if (element3.qty >= element4.min_quantity && element3.change_price > element4.product_price) {
                    element3.change_price = element4.product_price
                  }
                });
              }
            });
          }
        })
      }
    });
  }
  onResize() {
    if (window.innerWidth <= 768) {
      this.is_mobile_view = true;
    }
    else {
      this.is_web = true;
    }
  }
  checkCart() {

  }

  buyNow(prod) {
    this.cart_items = JSON.parse(localStorage.getItem('cart'));
    if (this.cart_items != null ) {
      this.cart_items.forEach(element => {
        if (element._id != prod._id) {
          var count: any;
          if (this.customer_group_name == 'dealer') {
            count = 5
          }
          if (this.customer_group_name == 'distributors') {
            count = 25
          }
          if (this.customer_group_name !== 'dealer' && this.customer_group_name !== 'distributors') {
            count = 5
          }
          this.cartservice.addProduct(prod, count);
          this.router.navigate(['checkout'])
          this.router.navigate(['checkout'])
        }
        this.router.navigate(['checkout'])
      });
    } else {
      var count: any;
      if (this.customer_group_name == 'dealer') {
        count = 5
      }
      if (this.customer_group_name == 'distributors') {
        count = 25
      }
      if (this.customer_group_name !== 'dealer' && this.customer_group_name !== 'distributors') {
        count = 5
      }
      this.cartservice.addProduct(prod, count);
      this.router.navigate(['checkout'])
    }

  }


  getAddress() {
    this.otherservice.getAddress().subscribe(res => {
      console.log(this.addressData)
      if (res['success'] == true) {
        if (res['data'].length > 0) {
          this.addressData = res['data'][0].address_location_id
          console.log(this.addressData)
          this.getDeliveryCharge(this.addressData)
        }
      }
    })
  }

  getDeliveryCharge(id) {
    this.otherservice.getDeliveryCharge(id).subscribe(resp => {
      if (resp.success == true) {
        this.min_order_amount = resp.data.min_order_amount_for_free;
        console.log(this.min_order_amount)
      }
    })
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
