<section id="innerbanner">
    <div class="container">
        <div class="row">
            <div class="breadcrumb">
                <h1 class="wow fadeInUp">Checkout</h1>
                <span class="wow fadeInUp"><a [routerLink]="['/']" title="Home">Home</a> / <a title="Products">Cart</a>
                    / <a title="Products">Checkout</a></span>
            </div>
        </div>
    </div>
</section>

<div class="clearfix"></div>


<section class="check_out_session">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="check_out_left product_inner">
                    <div class="addres_bar_h">
                        <span class="block">
                            <h6>Address</h6>
                        </span>
                    </div>
                    <div *ngIf=" customer_group_name !='distributors' && address.data && address.data.length == 0"
                        disabled>No Address Found</div>
                    <div *ngIf=" customer_group_name =='distributors'  && address.data && address.data.length == 0"
                        disabled>No Address Found! Please contact team
                        Kriztle</div>
                    <div class="addreS_section tl" *ngIf=" address.data && address.data.length > 0">
                        <table class="table1">
                            <tr *ngFor="let ad of address.data">
                                <td *ngIf="customer_group_name !='distributors'">
                                    <input type="radio" name="address" [checked]="(select_address._id==ad._id)"
                                        (click)="selectAddress(ad)">
                                </td>
                                <td class="ttc">
                                    <strong>{{ad.address_name}}</strong><br>
                                    {{ad.city.city_name}},
                                    {{ad.neighbourhood.neighbourhood_name}},

                                    {{ad.address_street_address}}, <br>

                                    <!-- {{ad.address_pincode}}, -->
                                    <strong>{{ad.address_mobile}}</strong>
                                </td>
                                <td class="tr" *ngIf="customer_group_name !='distributors'">
                                    <img (click)="deleteAddress(ad._id)" src="assets/images/close.png"
                                        class="rem_product pointer">
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="ad_ad_btn mt20" *ngIf="customer_group_name !='distributors'">
                        <div class="add_address_sec">
                            <button class="next_btn_cart adaddres" (click)="addAddress()">Add Address</button>
                        </div>
                        <p *ngIf="d_address" class="error">Please choose a address</p>
                    </div>

                    <ng-container *ngIf="customer_group_name !='distributors'">
                        <div class="addres_bar_h mt40">
                            <span class="block">
                                <h6>Delivery Date and Time</h6>
                            </span>
                        </div>
                        <p *ngIf="is_date" class="error">Please choose a Date</p>
                        <div class="ckt_delivery_time">
                            <button class="next_btn_cart adaddres" (click)="addDateTime('date')"
                                *ngIf='common.SelectedDate == null'>Please
                                choose
                                Date & Time</button>
                            <div class="pointer" (click)="addDateTime('date')" *ngIf='common.SelectedDate != null'>
                                <span class="date_des medium active_date"> {{ common.SelectedDate | date:'dd MMM
                                    yyyy'}},&nbsp;{{
                                    common.selectedTime | date:'h:mm a'}}</span>
                            </div>
                        </div>
                    </ng-container>


                    <div class="d_condition">
                        <div class="addres_bar_h mt40">
                            <span class="block">
                                <h6>Delivery Conditions</h6>
                            </span>
                        </div>
                        <div class="ckt_delivery_time">
                            <div class="ckt_d_t">
                                <p>
                                    Please ensure you read these terms and conditions with care. Please note all items
                                    must be declared with the correct Parcel Value when placing an order. This is the
                                    maximum amount that can be claimed in the event of a loss or damage. subject to all
                                    other Terms and Conditions and adequate parcel protection being purchased Should the
                                    parcel value be entered as zero. a claim will not be accepted on that booking.
                                </p>
                                <p class="prime">
                                    If you have any questions. or don't understand something. just get in contact with
                                    us and well be happy to help.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-4">
                <div class="check_out_right product_inner">
                    <div class="check_out_price">
                        <div class="addres_bar_h">
                            <span class="block">
                                <h6>Payment Method</h6>
                            </span>
                        </div>
                        <div class="addreS_section tl">
                            <table class="table2">
                                <tr [ngClass]="{'active_method':payment_method=='online'}">
                                    <td><input type="radio" #op value="online" name="payment"
                                            (click)="paymentMethod(op.value)" [checked]="payment_method=='online'"></td>
                                    <td #op (click)="paymentMethod(op.value)">Online Payment</td>
                                </tr>
                                <!-- <tr [ngClass]="{'active_method':payment_method=='cod'}">
                                    <td><input type="radio" #cod value="cod" name="payment"
                                            (click)="paymentMethod(cod.value)" [checked]="payment_method=='cod'"></td>
                                    <td #cod (click)="paymentMethod(cod.value)">Cash on Delivery</td>
                                </tr> -->
                                <tr [ngClass]="{'active_method':payment_method=='bank_transfer'}">
                                    <td><input type="radio" #net_banking value="bank_transfer" name="payment"
                                            (click)="paymentMethod(net_banking.value)"
                                            [checked]="payment_method=='bank_transfer'"></td>
                                    <td #net_banking (click)="paymentMethod(net_banking.value)">Net Banking/Bank
                                        Transfer</td>
                                </tr>
                            </table>
                        </div>
                        <p *ngIf="is_payment" class="error">Please Choose payment Method</p>
                        <div class="addreS_section tl mt20" *ngIf='isCod'>
                            <div class="cod tc">
                                <div class="sbi-bg">
                                    <img *ngIf="imgURL == null" src="assets/images/sbi.png" class="imag-size">
                                    <img *ngIf="imgURL != null" src="{{imgURL}}" class="imag-size">
                                    <div class="line-white"></div>
                                    <p class="text-white-sbi1">SHAN INTERNATIONAL <br>State Bank of India,
                                        <br>Current
                                        Account.<br>No:
                                        37247655589 <br>Manjeri Town Branch<br> NEFT/RTGS IFSC Code SBIN0070197
                                    </p>
                                </div>
                                <div class="file_div tc">
                                    <input id="files" class="f_div hidden" type="file" name="image" accept="image/*"
                                         (change)="fileChange($event)">
                                    <label for="files" class="upload_dedails">
                                        <img src="assets/images/upload.svg">
                                        Upload Transaction Details</label>
                                </div>
                            </div>
                        </div>
                        <div class="addreS_section_v mt20">
                            <ng-container *ngIf="customer_group_name !='distributors'">
                                <div class="addres_bar_h">
                                    <span class="block">
                                        <h6 class="mb0">Delivery Method</h6>
                                        <p class="calls-text-cristle">(Kriztle calls before making Delivery)</p>
                                    </span>
                                </div>
                                <div class="addreS_section_fill tl">
                                    <table class="table2">
                                        <tr [ngClass]="{'active_method':delivery_place=='party'}">
                                            <td><input type="radio" #pa value="party" name="area"
                                                    (click)="delieryArea(pa.value)" [checked]="delivery_place=='party'">
                                            </td>
                                            <td #pa (click)="delieryArea(pa.value)">Party Hall / Auditorium</td>
                                        </tr>
                                        <tr [ngClass]="{'active_method':delivery_place=='room'}">
                                            <td><input type="radio" #ro value="room" name="area"
                                                    (click)="delieryArea(ro.value)" [checked]="delivery_place=='room'">
                                            </td>
                                            <td #ro (click)="delieryArea(ro.value)">Your Room</td>
                                        </tr>

                                        <tr [ngClass]="{'active_method':delivery_place=='Kitchen'}">
                                            <td><input type="radio" #ke value="Kitchen" name="area"
                                                    (click)="delieryArea(ke.value)"
                                                    [checked]="delivery_place=='Kitchen'"></td>
                                            <td #ke (click)="delieryArea(ke.value)">Kitchen / Godown / House</td>
                                        </tr>
                                        <tr [ngClass]="{'active_method':delivery_place=='reception'}">
                                            <td><input type="radio" #re value="reception" name="area"
                                                    (click)="delieryArea(re.value)"
                                                    [checked]="delivery_place=='reception'"></td>
                                            <td #re (click)="delieryArea(re.value)">Leave at Reception</td>
                                        </tr>
                                    </table>
                                </div>
                            </ng-container>
                            <p *ngIf="d_place" class="error">Please choose delivery place</p>
                            <div class="addres_bar_h mt20">
                                <span class="block">
                                    <h6 class="mb0">Order Summary</h6>
                                </span>
                            </div>
                            <div class="addreS_section mt5 tl" *ngIf="cart_items && cart_items.length > 0">
                                <table class="table2">
                                    <tr *ngFor="let item of cart_items">
                                        <td>{{item.product_name}}</td>
                                        <td>₹{{item.change_price| number:'1.2-2'}} x {{item.qty}} (Box)</td>
                                        <td>₹{{item.change_price * item.qty | number:'1.2-2'}}</td>
                                    </tr>
                                    <tr>
                                        <th class="th_om">Total</th>
                                    </tr>
                                    <tr>
                                        <td colspan="2">Product Total</td>
                                        <td>₹{{total_price | number:'1.2-2'}}</td>
                                    </tr>
                                    <tr *ngIf="delivery_charge">
                                        <td colspan="2">Delivery Charge</td>
                                        <td>₹{{delivery_charge| number:'1.2-2'}}</td>
                                    </tr>
                                    <tr *ngIf="!delivery_charge">
                                        <td colspan="2">Delivery Charge</td>
                                        <td>₹{{0| number:'1.2-2'}}</td>
                                    </tr>
                                    <tr *ngIf="calculated_Total">
                                        <td colspan="2">Grand Total</td>
                                        <td>₹{{calculated_Total | number:'1.2-2'}}</td>
                                    </tr>
                                    <tr *ngIf="!calculated_Total">
                                        <td colspan="2"><strong>Grand Total</strong></td>
                                        <td><strong>₹{{total_price | number:'1.2-2'}}</strong></td>
                                    </tr>
                                </table>
                            </div>



                            <div class="check_out_button mt20">
                                <button *ngIf="customer_group_name !='distributors'" (click)="placeOrder()"
                                    class="next_btn_cart width100">Place Order </button>
                                <button *ngIf="customer_group_name =='distributors'" (click)="continue()"
                                    class="next_btn_cart width100">Place Order </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="loader_order" *ngIf="is_place">
    <div class="load_box">
        <ngx-moon-loader class="item"></ngx-moon-loader>
    </div>
</div>