<section class="modal_section_div">
  <button mat-dialog-close class="close_md_date"> 
      <img  src="assets/images/close.svg" class="rem_product pointer">
   </button>
  <div class="container make_it_center">
      <div class="row">
          <div class="col-md-12">
            <div class="order_re">
              <mat-radio-group name="flavors" (change)="radioChange($event)">
                <table class="order_cn_radio">
                  <tr>
                    <td>
                      <mat-radio-button value="mind changed" checked="true"></mat-radio-button>
                    </td>
                    <td>
                      Mind Changed
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <mat-radio-button value="order by mistake"></mat-radio-button>
                    </td>
                    <td>
                      Order By Mistake
                    </td>
                  </tr>
                </table>
              </mat-radio-group>
              <button class="view_or_detail mt20" (click)="dialogRef.close()">Cancel</button>
            </div>
          </div>
      </div>
  </div>
</section>
