import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user_details=JSON.parse(localStorage.getItem('user_details'));

  constructor() { 
    this.loggedIn();
   }

  loggedIn(){
    return !!localStorage.getItem('api_token');
  }
}
