<section class="modal_section_div">
    <button mat-dialog-close class="close_md_date"> 
        <img  src="assets/images/close.svg" class="rem_product pointer">
     </button>
    <div class="container make_it_center">
        <div class="row">
            <div class="col-md-12">
                <h2>Add Address</h2>
                <hr>
            </div>
            <form [formGroup]='addressForm'>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="col-md-12">
                                <div class="map_ds">
                                    <agm-map (mapClick)="chooseLocation($event)" [latitude]="latitude" [longitude]="longitude">
                                        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                                    </agm-map>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="col-md-12">
                                <input type="text" class="profile-inp2" placeholder="Name*" formControlName="name">
                                <div *ngIf="submitted && acf.name.errors">
                                    <div class="form-error-msg" *ngIf="acf.name.errors.required">*Name is
                                        required</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <input type="number" class="profile-inp2" placeholder="Contact Number*" formControlName="number">
                                <div *ngIf="submitted && acf.number.errors">
                                    <div class="form-error-msg" *ngIf="acf.number.errors.required">*Mobile Number is required</div>
                                    <div class="form-error-msg" *ngIf="acf.number.errors.pattern || acf.number.errors.minlength">
                                        Enter Valid mobile number</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <input type="email" class="profile-inp2" placeholder="Email" formControlName="email">
                            </div>

                            
                            <div class="col-md-12">
                                <input type="text" class="profile-inp2" [(ngModel)]="streetAddress"
                                    placeholder="Delivery/Shipping Address*" formControlName="address">
                                    <div *ngIf="submitted && acf.address.errors">
                                        <div class="form-error-msg" *ngIf="acf.address.errors.required">*Street address is
                                            required</div>
                                    </div>
                            </div>
                            <!-- <div class="col-md-12">
                                <input type="text" class="profile-inp2" placeholder="Village" formControlName="village">
                                <div *ngIf="submitted && acf.village.errors">
                                    <div class="form-error-msg" *ngIf="acf.village.errors.required">*Village is
                                        required</div>
                                </div>
                            </div> -->
                            <div class="col-md-12">
                                <input type="text" class="profile-inp2" placeholder="Muncipality/Corprate/Panchayath*" formControlName="muncipality">
                                <div *ngIf="submitted && acf.muncipality.errors">
                                    <div class="form-error-msg" *ngIf="acf.muncipality.errors.required">*Muncipality is
                                        required</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <select (change)="loadNeighborhood($event)" formControlName="city" class="profile-inp2">
                                    <option selected value="" >Choose District</option>
                                    <option disabled value="" >Free Delivery Only Kerala State</option>
                                    <option *ngFor="let city of cities" value={{city._id}}>{{city.city_name}}</option>
                                </select>
                                <div *ngIf="submitted && acf.city.errors">
                                    <div class="form-error-msg" *ngIf="acf.city.errors.required">*Disdtrict is
                                        required</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <select (change)="getNighberhood($event)" formControlName="location" class="profile-inp2">
                                    <option selected value="" >Choose Taluk</option>
                                    <option disabled value="" >Free Delivery Only Kerala State</option>
                                    <option *ngFor="let loc of neighbourhood" value={{loc._id}}>{{loc.neighbourhood_name}}</option>
                                </select>
                                <div *ngIf="submitted && acf.location.errors">
                                    <div class="form-error-msg" *ngIf="acf.location.errors.required">*Location is
                                        required</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <input type="number" class="profile-inp2" [(ngModel)]="postcode" placeholder="Pincode*"
                                    formControlName="pincode">
                                    <div *ngIf="submitted && acf.pincode.errors">
                                        <div class="form-error-msg" *ngIf="acf.pincode.errors.required">*Pincode is
                                            required</div>
                                    </div>
                            </div>
                            
                           
                            <div class="col-md-12 tr">
                                <button type="button" class="next_btn_cart" (click)="addAddress()">Save Address</button>
                            </div>
                        </div>
                    </div>
                </div>
                 
                
            </form>
        </div>
    </div>
</section>

