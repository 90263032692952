<!-- enquiry -->

<section class="enquiry">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-xs-12 tl xs_tr xs_mb20">
                <h2>Let us here from You..</h2>
            </div>
            <div class="col-md-6 col-xs-12 tr xs_tr">
                <a [routerLink]="['/contact']">Enquire Now</a>
            </div>
        </div>
    </div>
</section>
<!--// enquiry -->

<div class="clearfix"></div>

<!-- footer -->
<footer>
    <div class="container">
        <div class="row">
            <div class="col-md-12 footer-top">

                <div class="col-md-4 hidden-xs">
                    <h4 class="wow fadeInUp">Quick Links</h4>
                    <div class="col-md-6 zero footerlinks">
                        <a [routerLink]="['/']" class="wow fadeInUp">Home</a>
                        <a [routerLink]="['/about']" class="wow fadeInUp">About</a>
                    </div>
                    <div class="col-md-6 zero footerlinks">
                        <a [routerLink]="['/products']" class="wow fadeInUp">Products</a>
                        <a [routerLink]="['/contact']" class="wow fadeInUp">Contact Us</a>
                    </div>
                </div>

                <div class="col-md-4 footerlinks">
                    <div class="col-md-12 zero">
                        <h4 class="wow fadeInUp">Contact Us</h4>
                        <div class="col-md-12 zero">
                            <span class="wow fadeInUp"><img src="assets/images/phone.png" class="img-responsive"
                                    alt="Phone" /></span>
                            <a href="tel:+919207570570" class="wow fadeInUp">+91 9207 570 570</a>
                            <span class="wow fadeInUp"><img src="assets/images/phone.png" class="img-responsive"
                                    alt="Phone" /></span>
                            <a href="tel:+919947777140" class="wow fadeInUp">+91 9947 777 140</a>
                            <span class="wow fadeInUp"><img src="assets/images/mail.png" class="img-responsive"
                                    alt="mail" /></span>
                            <a href="mailto:kriztleglobal@gmail.com" class="wow fadeInUp">kriztleglobal@gmail.com</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <h4 class="wow fadeInUp">Find Us</h4>
                    <div class="col-md-12 zero footerlinks">
                        <p class="wow fadeInUp">Shan International, Shan Plaza, <br>
                            10/364 E, Ooty Road, Cherani - 676 123, <br>
                            Kerala State - INDIA</p>
                    </div>
                </div>
                <br>
                <div class="col-md-3 col-xs-6"><img src="assets/images/isi2.png" class="img-responsive fssai" /></div>
                <div class="col-md-3 col-xs-6"><img src="assets/images/fssai2.png" class="img-responsive isi" /></div>

            </div>

            <div class="col-md-12 footer-bottom">
                <div class="col-md-6 col-xs-9 copyright zero">
                    <p>© 2021 Kriztle, All rights reserved. <span> Design by <a style="color:#afd13a;" href="http://www.eximuz.com/"
                                target="_blank">Eximuz Technolabs</a></span></p>
                </div>
                <div class="col-md-6 footer-social tr zero">
                   <a class="black pointer" [routerLink]="['/privacy']">Privacy Policy</a> | <a class="pointer" [routerLink]="['/terms']">Terms & Conditions</a>
                    | <a class="black pointer" [routerLink]="['/refund-policy']">Refund Policy</a>  | <a class="black pointer" [routerLink]="['/shipping-policy']">Shipping Policy</a> 
                    <a href="#" target="_blank"><img src="assets/images/insta.png" class="img-responsive" /></a>
                    <a href="https://www.facebook.com/kriztle.g" target="_blank"><img src="assets/images/fb.png"
                            class="img-responsive" /></a>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--// footer -->