// import {LoginPage} from './../pages/login/login';
import { Injectable } from '@angular/core';
// import { ToastController, ModalController, LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { OtherService } from './other.service';

@Injectable({ providedIn: 'root' })
export class CommonProvider {
    baseUrl = 'http://beta.api.anjos.shopping/orders';
    
    USER_LOGIN = "user:login";
    USER_LOGOUT = 'user:logout';
    USER_PROFILE_UPDATED = 'user:profileUpdated';
    USER_ORDER_UPDATED = 'user:orderUpdated';

    CART_UPDATED = 'cart:updated';
    total_amount:any;
    total_qty:any;
    STORE = 'store';
    CART = 'cart';
    hasLoggedIn:boolean=false;
    LOGIN='logout';
    VENDOR: 'anjosvendor';
    NEW_NOTIFICATION='foodSignNotification';
    FCM_TOKEN='anjosFcmToken'
    USER_ADDRESS='anjosUserAddress'
    // AVAILABLE DAY TYPE
    availableDayaType = {
        'Every Day': 0,
        'Weekly': 1,
        'Monthly': 2
    }

    max_scheduled_days:number = 0
    cod_enabled:any;
    payment_gateway_enabled:any
    instant_delivery_available_time:any = null
    schedule_delivery_available_time:any =null
    selectedLocation:any= null
    selectedCity:any= null
    SelectedDate:any= null
    selectedTime:any = null
    selectedTimeArray:any=[]
    DateArray:any=[]
    tempDateArray:any=[]
    todayDate = new Date()
    dateTimeSettings:any=[]
    maxScheduledDays:number
    isInside:boolean =false
    constructor(private toastr: ToastrService,public other:OtherService) { }
    


    // schedule days

    
    showSuccess(data) {
        this.toastr.success(data);
      }
  
      groupBy(dateArray) {
        var groups = {};
        for (var i = 0; i < dateArray.length; i++) {
        var groupName = dateArray[i].month;
        if (!groups[groupName]) {
           groups[groupName] = [];
        }
         groups[groupName].push(dateArray[i]);
        }
         for (var monthName in groups) {
          this.DateArray.push({month: monthName, date: groups[monthName]});  
         }

         console.log(this.DateArray)
    }
  
   getVacantDays(date){
      switch (moment(date).format('ddd')) {
        case 'Sun':
          return []
          break;
        case 'Mon':
          return [[0]]
          break;
        case 'Tue':
          return [[0],[1]]
          break;
        case "Wed":
          return [[0],[1],[2]]
          break;
        case "Thu":
          return [[0],[1],[2],[3]]
          break;
        case "Fri":
          return [[0],[1],[2],[3],[4]]
          break;
        case "Sat":
          return [[0],[1],[2],[3],[4],[5]]
          break;
        default:
          return []
      }
    }
    getDays(){
      this.other.commonSettings().subscribe(res =>{
        let result:any=res;
        if(result.success == true){
          this.maxScheduledDays=result.data.maximum_schedule_days;
          console.log(this.maxScheduledDays)
          this.DateArray = []
          this.tempDateArray = []
          for(let i=0;i< this.maxScheduledDays;i++){
            let date =  moment().add(i, 'days');
            let day = moment(date).format('ddd')
            let month = moment(date).format('MMMM')
            this.tempDateArray.push({'date':date,'day':day,'month':month,'availability':this.isValidDate(date,day)})
           }
           this.groupBy(this.tempDateArray)
           console.log(this.tempDateArray)
        }
      })
       
         
      }
      isValidDate(date,day){
        // console.log(this.dateTimeSettings)
        if(this.dateTimeSettings && this.dateTimeSettings.days){
          this.dateTimeSettings.day.forEach(days => {
           
            if( (moment(days.date).isSame(moment(date._d).format('DD-MM-YYYY')) && days.time && days.time.length > 0)){
              return true
            }
          });
        }
          if(day =="Sun" && this.dateTimeSettings.sun && this.dateTimeSettings.sun.length > 0){
           return true
          }
           else if(day =="Mon" && this.dateTimeSettings.mon && this.dateTimeSettings.mon.length > 0){
            return true
           }
           else if(day =="Tue" && this.dateTimeSettings.tue && this.dateTimeSettings.tue.length > 0){
            return true
            }
            else if(day =="Wed" && this.dateTimeSettings.wed && this.dateTimeSettings.wed.length > 0){
              return true
         }
        else if(day =="Thu" && this.dateTimeSettings.thu && this.dateTimeSettings.thu.length > 0){
          return true
         }
       else if(day =="Fri" && this.dateTimeSettings.fri && this.dateTimeSettings.fri.length > 0){
        return true
         }
       else if(day =="Sat" && this.dateTimeSettings.sat && this.dateTimeSettings.sat.length > 0){
        return true
        }
        else{
          return false
        }
       }
       
        isValidTime(value){
          var format = 'HH:mm a'  
          var currentDate  = new Date()
          var selectedTime = moment(value,format)
          var SelectDate = moment(this.SelectedDate).format('YYYY-MM-DD');
          var todaysDate = moment(currentDate).format('YYYY-MM-DD');
          var todaysTime = moment(currentDate,format)
          console.log(this.dateTimeSettings.day)
          this.dateTimeSettings.day.forEach(days => {
            // console.log(days)
            // if( (moment(days.date).isSame(moment(this.SelectedDate).format('DD-MM-YYYY')) && days.time && days.time.length > 0)){
            //   days.time.forEach(time => {
            //   this.isInside =true
            //   var startTime  =  moment(time.from,format)
            //   var endTime =  moment(time.to,format)
            //   console.log(startTime,endTime)
            //   if (selectedTime.isBetween(startTime, endTime)) {
            //      if(todaysDate < SelectDate){
            //         this.selectedTimeArray.push(selectedTime)
            //       }
            //      else if(todaysTime < selectedTime){
            //       this.selectedTimeArray.push(selectedTime)
            //     }
            //     }
            //   });
            // }
          });
          if(moment(this.SelectedDate).format('ddd') =="Sun" && !this.isInside && this.dateTimeSettings.sun && this.dateTimeSettings.sun.length > 0){
            this.dateTimeSettings.sun.forEach(time => {
              var startTime  = moment(time.from,format)
              var endTime = moment(time.to,format)
              console.log(startTime,endTime)
                if (selectedTime.isBetween(startTime, endTime,null, '[)')) {
                 if(todaysDate < SelectDate){
                    this.selectedTimeArray.push(selectedTime)
                  }
                 else if(todaysTime < selectedTime){
                  this.selectedTimeArray.push(selectedTime)
                }
                }
              });
           }
           else if(moment(this.SelectedDate).format('ddd') =="Mon" && !this.isInside &&  this.dateTimeSettings.mon && this.dateTimeSettings.mon.length > 0){
            this.dateTimeSettings.mon.forEach(time => {
              var startTime  = moment(time.from,format)
              var endTime = moment(time.to,format)
              console.log(startTime,endTime)
                 if (selectedTime.isBetween(startTime, endTime, null, '[)')) {
                 if(todaysDate < SelectDate){
                   console.log(selectedTime)
                    this.selectedTimeArray.push(selectedTime)
                  }
                 else if(todaysTime < selectedTime){
                  this.selectedTimeArray.push(selectedTime)
                  console.log(selectedTime)
                }
                }
              });
           }
           else if(moment(this.SelectedDate).format('ddd') =="Tue" && !this.isInside &&  this.dateTimeSettings.tue && this.dateTimeSettings.tue.length > 0){
            this.dateTimeSettings.tue.forEach(time => {
              var startTime  = moment(time.from,format)
              var endTime = moment(time.to,format)
              console.log(startTime,endTime)
                 if (selectedTime.isBetween(startTime, endTime,null, '[)')) {
                 if(todaysDate < SelectDate){
                    this.selectedTimeArray.push(selectedTime)
                    console.log(selectedTime)
                  }
                 else if(todaysTime < selectedTime){
                  this.selectedTimeArray.push(selectedTime)
                }
                }
              });
            }
            else if(moment(this.SelectedDate).format('ddd') =="Wed" && !this.isInside &&  this.dateTimeSettings.wed && this.dateTimeSettings.wed.length > 0){
              this.dateTimeSettings.wed.forEach(time => {
                var startTime  = moment(time.from,format)
              var endTime = moment(time.to,format)
              console.log(startTime,endTime)
                 if (selectedTime.isBetween(startTime, endTime,null, '[)')) {
                 if(todaysDate < SelectDate){
                    this.selectedTimeArray.push(selectedTime)
                  }
                 else if(todaysTime < selectedTime){
                  this.selectedTimeArray.push(selectedTime)
                }
                }
              });
         }
        else if(moment(this.SelectedDate).format('ddd') =="Thu" && !this.isInside &&  this.dateTimeSettings.thu && this.dateTimeSettings.thu.length > 0){
          this.dateTimeSettings.thu.forEach(time => {
            var startTime  = moment(time.from,format)
              var endTime = moment(time.to,format)
              console.log(startTime,endTime)
                 if (selectedTime.isBetween(startTime, endTime,null, '[)')) {
                 if(todaysDate < SelectDate){
                    this.selectedTimeArray.push(selectedTime)
                  }
                 else if(todaysTime < selectedTime){
                  this.selectedTimeArray.push(selectedTime)
                }
                }
              });
         }
       else if(moment(this.SelectedDate).format('ddd') =="Fri" && !this.isInside &&  this.dateTimeSettings.fri && this.dateTimeSettings.fri.length > 0){
        this.dateTimeSettings.fri.forEach(time => {
          var startTime  = moment(time.from,format)
              var endTime = moment(time.to,format)
              // console.log(startTime,endTime)
                 if (selectedTime.isBetween(startTime, endTime,null, '[)')) {
                 if(todaysDate < SelectDate){
                    this.selectedTimeArray.push(selectedTime)
                    console.log(selectedTime)
                  }
                 else if(todaysTime < selectedTime){
                  this.selectedTimeArray.push(selectedTime)
                          console.log(selectedTime)
                }
                }
              });
         }
       else if(moment(this.SelectedDate).format('ddd') =="Sat" && !this.isInside &&  this.dateTimeSettings.sat && this.dateTimeSettings.sat.length > 0){
        this.dateTimeSettings.sat.forEach(time => {
          var startTime  = moment(time.from,format)
              var endTime = moment(time.to,format)
              console.log(startTime,endTime)
                 if (selectedTime.isBetween(startTime, endTime,null, '[)')) {
                  if(todaysDate < SelectDate){
                    this.selectedTimeArray.push(selectedTime)
                  }
                 else if(todaysTime < selectedTime){
                  this.selectedTimeArray.push(selectedTime)
                }
                }
              });
        }
        else{
          return false
        }
        }
    
}
