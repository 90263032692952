import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from '../../_helpers/validators';
import { UserService } from '../../providers/user.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-contact', 
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

   contactForm:FormGroup
   validator=new Validator
   submitted:boolean=false;

  constructor(public formBuilder:FormBuilder,public user:UserService,public toaster:ToastrService) { }

  ngOnInit() {
      this.contactForm=this.formBuilder.group({
      name:['', Validators.required],
      email:['',[Validators.required, Validators.pattern(this.validator.emailPattern())]],
      phone:['',[Validators.required,Validators.pattern(this.validator.numberPattern()), Validators.minLength(10)]],
      subject:['',Validators.required],
      message:['',Validators.required]
      })
    
  }
  
  get acf() { return this.contactForm.controls; }

  sendData(){
  this.submitted=true;
  if(this.contactForm.valid){
    var data={
      name:this.acf.name.value,
      email:this.acf.email.value,
      phone:this.acf.phone.value,
      subject:this.acf.subject.value,
      message:this.acf.message.value
    }
    this.user.sendContactData(data).subscribe(resp=>{
      if(resp.success==true){
      this.toaster.success("successfully sent data")
      this.contactForm.reset();
      this.submitted=false;
      }else{
        this.toaster.success("something went wrong")
      }

    })
  }
  }
}
