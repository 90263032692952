
    <!-- Top navigation -->
    <app-topnavbar></app-topnavbar>

    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <app-footer></app-footer>

