<section id="innerbanner">
    <div class="container">
        <div class="row">
            <div class="breadcrumb">
                <h1>My Orders</h1>
                <span><a [routerLink]="['/']" title="Home">Home</a> / <a title="Products">Orders</a></span>
            </div>
        </div>
    </div>
</section>

<div class="clearfix"></div>


<section class="order_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 mb20 tr">
                <div class="select_status">
                    <select class="sele_cao" (change)="selectionChanged($event)">
                        <option *ngFor="let i of selectItems" value="{{i}}">{{ i }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12" *ngIf="orders && orders.length == 0 ">
                <div class="nof">No Orders Found!</div>
            </div>
            <div class="col-md-12" *ngFor="let prod of orders" (click)="trackOrder(prod._id)">
                <div class="myorderlist product_inner mb20">
                    <div class="row order-area">
                        <div class="col-md-4 orderinfo">
                            <h5 class="product_name">ORDER DETAILS</h5>
                            <div class="col-md-12 zero orderid">
                                <div class="col-md-4 zero m_top5"> <strong>Order ID</strong> </div>
                                <div class="col-md-8 zero">{{prod._id}} &nbsp;<span
                                        *ngIf="prod.no_of_products == 1">({{prod.no_of_products}} item)</span> <span
                                        *ngIf="prod.no_of_products != 1">({{prod.no_of_products}} items)</span> </div>
                            </div>
                            <div class="col-md-12 zero orderid">
                                <div class="col-md-4 zero m_top5"> <strong>Order Date</strong> </div>
                                <div class="col-md-8 zero">{{prod.created_at |date:'medium'}}</div>
                            </div>
                            <div class="col-md-12 zero orderid">
                                <div class="col-md-4 zero m_top5"> <strong>Total Amount</strong> </div>
                                <div class="col-md-8 zero">₹ {{prod.order_amount}} through <span
                                        class="ng-star-inserted" *ngIf="prod.payment_mode=='cod'">Cash on
                                        delivery</span> <span class="ng-star-inserted"
                                        *ngIf="prod.payment_mode=='online'">Online Payment</span> <span
                                        class="ng-star-inserted" *ngIf="prod.payment_mode=='net_banking'">Net
                                        Banking</span>
                                    <!---->
                                    <!---->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 orderinfo">
                             
                            <div class="col-md-12 zero orderid">
                                <div class="col-md-3 zero m_top5"> <strong>Address</strong> </div>
                                <div class="col-md-9 zero">{{prod?.shipping_address?.address_name}} <br> {{prod?.shipping_address?.address_street_address}}</div>
                            </div>

                            <div class="col-md-12 zero orderid">
                                <div class="col-md-3 zero m_top5"> <strong>Phone</strong> </div>
                                <div class="col-md-9 zero">{{prod?.shipping_address?.address_mobile}}</div>
                            </div>
                        </div>
                        <div class="col-md-3 orderinfo">
                            <!-- <h5 >Manage Order</h5> -->
                            <div class="status_btn">
                                <button class="status_badge">{{prod.order_status}}</button>
                                <button class="view_or_detail">View Detail</button>
                            </div>
                            <!-- <div  class="col-md-12 zero orderid">
                                    <div  class="col-md-12 zero"><a  href="#" class="cncel" style="float: left;"><i  class="fa fa-file-alt"></i> Request Invoice</a></div>
                                </div> -->
                        </div>
                    </div>
                </div>
 
            </div>
        </div>

        <div class="row" *ngIf="orders===null||orders===undefined">
            <div class="col-md-12">
                <div>
                    <h2 class="product_name">No Items Found</h2>
                </div>
            </div>
            <hr>
        </div>
    </div>


    <!-- <div class="row">
        <div class="item col-md-3" *ngFor="let prod of orders" (click)="trackOrder(prod._id)">
            <div class="card">
                
                <div class="product-caption">
                    <div class="col-md-6 col-xs-6">
                        <h3>{{ prod.product_name }}</h3>
                        <hr>
                        <p></p>
                    </div>
                    <div class="col-md-6 col-xs-6">
                        <h5 class="pull-right"
                            style="font-size: 13px; color: #777; font-weight: 400; margin: 0; padding-right: 15px;">
                            {{ prod.product_description }}</h5>
                    </div>
                    <div class="col-md-12 col-xs12">
                        <p>Rs. {{prod.order_amount | number:'.2-2'}}</p>
                    </div>
                    <div class="col-md-12 col-xs12">
                        <p></p>
                    </div>
                </div>
            </div>
        </div>

        
    </div> -->
    <div class="clearfix"></div>
    <section></section>
</section>