import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.css']
})
export class CancelOrderComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CancelOrderComponent>) { }

  ngOnInit(): void {
  }


  radioChange(event){
    console.log(event.value);
    if(confirm("Are you sure you want to delete")){
    this.dialogRef.close({
      'value':event.value
    })
  }
}
}
