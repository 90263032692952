import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonProvider } from 'src/app/providers/common';
import { OtherService } from 'src/app/providers/other.service';
import { UserService } from 'src/app/providers/user.service';
import { MatDialog } from '@angular/material';
import { AddProfileComponent } from '../add-profile/add-profile.component';
import { AddAddressComponent } from '../add-address/add-address.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CartService } from '../../providers/cart.service';
import { EventPublishService } from '../../providers/event-publish';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  address:any;
  profile:any;
  user_details:any=JSON.parse(localStorage.getItem('user_details'));

  constructor(private userservice:UserService, private otherservice:OtherService, private router:Router,public common:CommonProvider,
    public dailog:MatDialog,public cart:CartService,public events:EventPublishService) { }

  ngOnInit() {
    this.loadProfile();
    this.getAddress();
  }

  loadProfile(){
    this.otherservice.getProfile().subscribe(res=>{
      let response:any=res;
      this.profile=response.data;
      console.log("profile data",this.profile);
    })
  }

  getAddress(){
    this.otherservice.getAddress().subscribe(res=>{
      let result:any=res;
      if (result.success==true) {
        this.address=result.data;
      }
      console.log("address..",this.address);
    })
  }

  delete(id){
    this.otherservice.deleteAddress(id).subscribe(res=>{
      let response:any=res;
      if (response.success == true) {
        this.router.navigate(['/home']).then(r =>{
          this.router.navigate(['/profile']);
        })
      }
      
    })
  }




  editProfile(){
    const dialogRef = this.dailog.open(AddProfileComponent, {
      panelClass:"logi_modal",
      data:{
      id:this.profile
      }
    })

    dialogRef.afterClosed().subscribe(result=> {
     this.loadProfile(); 
    });
    
  }

  editAddress(){
    console.log("kkkkkkkkkkkkk")
    const dialogRef = this.dailog.open(AddAddressComponent, {
      panelClass:"logi_modal"
      // data:{
      // id:data
      // }
    })

    dialogRef.afterClosed().subscribe(result=> {
     this.getAddress(); 
    });
    
  }




  logout(){
    this.userservice.isuser=false;
    this.common.hasLoggedIn=false; 
    localStorage.setItem("isUser",JSON.stringify(false));
    localStorage.setItem("user",JSON.stringify(false));
    localStorage.removeItem("api_token");
    localStorage.removeItem("user_details");
    localStorage.removeItem("cart"); 
    this.userservice.hasLoggedIn=false;
    this.otherservice.total_price=0; 
    this.cart.total_qty=0;
    this.common.showSuccess("successfully logged out")
    this.profile='';
    this.router.navigate(['/tabs/home']);
    this.events.publishSomeData("logout")
  }

  deleteAddress(id){
    this.otherservice.deleteAddress(id).subscribe(res=>{
      let response:any=res;
      console.log("pro res",response);
      
      if (response.success == 'true') {
        this.getAddress();
      }
      
    })
  }
}
