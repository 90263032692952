import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.gotoTop();
  }

  gotoTop() {
    document.body.scrollTop = 0

    setTimeout(function(){
      // if (window.location.href.indexOf("privacy") > -1) {
      //   $("html, body").animate({ scrollTop: $("#privacy").offset().top }, 500);
        
      // }
      if (window.location.href.indexOf("refund-policy") > -1) {
        $("html, body").animate({ scrollTop: $("#refund-policy").offset().top }, 500);
        
      }
      if (window.location.href.indexOf("shipping-policy") > -1) {
        $("html, body").animate({ scrollTop: $("#shipping-policy").offset().top }, 500);
        
      }
     }, 300);

    
  }

}
